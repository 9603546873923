import React from "react";

import { SQFTLOT, Gym, Dog } from "./assets/svgs";

import "./style/home.scss";

const ModalBuildingDetails = ({ listing }) => {
  const unit = listing.unit;
  unit.address = unit.address.replace(",,", ",");

  const area_link = `/area/${unit.area.replace(" ", "_")}`;
  return (
    <>
      <div className="top-container__header">
        <div className="right-header">
          <div className="price-plot-title swiper-no-swiping">
            {unit.building_name}
          </div>
          <p className="show-address">{unit.address}</p>
        </div>
      </div>

      <div className="listing-info">
        <div className="listing-info__header-menu">
          <ul className="details">
            <li>
              {<SQFTLOT />}{" "}
              {unit.area}
              {/* <Link to={area_link} className="area-link">
                {unit.area}
              </Link>{" "} */}
            </li>
            <li>
              {<Gym />} {unit.has_gym ? "Has gym" : "No gym"}
            </li>
            <li>
              {<Dog />}{" "}
              {unit.pet_friendly === undefined || unit.pet_friendly
                ? "Pet friendly"
                : "No pets"}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ModalBuildingDetails;
