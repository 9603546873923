import { csrfFetch } from "./csrf";
import { receiveUser } from "./usersReducer";

const CHECK_EMAIL_REQUEST = "CHECK_EMAIL_REQUEST";
const CHECK_EMAIL_SUCCESS = "CHECK_EMAIL_SUCCESS";
const CHECK_EMAIL_FAILURE = "CHECK_EMAIL_FAILURE";
const LOGIN_REQUEST = "LOGIN_REQUEST";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAILURE = "LOGIN_FAILURE";

export const abortLoginAction = () => {
  return { type: LOGIN_FAILURE, payload: "user clicked away" };
};

export const checkEmail = (email) => async (dispatch) => {
  dispatch({ type: CHECK_EMAIL_REQUEST, payload: email });
  try {
    const res = await csrfFetch(
      `/auth/has_account?email=${encodeURIComponent(email)}`
    );
    if (res.ok) {
      const has_account = await res.json();
      console.log("has_account", has_account);
      dispatch({ type: CHECK_EMAIL_SUCCESS, payload: has_account });
    }
  } catch (error) {
    dispatch({ type: CHECK_EMAIL_FAILURE, payload: error.message });
  }
};

export const tryLogin = (email, password) => async (dispatch) => {
  console.log("tryLogin", email, password);
  dispatch({ type: LOGIN_REQUEST, payload: password });
  try {
    const res = await csrfFetch(
      `/auth/password?email=${encodeURIComponent(
        email
      )}&password=${encodeURIComponent(password)}`
    );
    if (res.ok) {
      const result = await res.json();
      console.log("login response", result);
      dispatch({ type: LOGIN_SUCCESS });
      dispatch(receiveUser(result));
    }
  } catch (error) {
    dispatch({ type: LOGIN_FAILURE, payload: error.message });
  }
};

export const createAccount =
  (email, password = null, name = null, phone = null) =>
  async (dispatch) => {
    dispatch({ type: LOGIN_REQUEST, payload: password });
    const user_info = { email };
    if (password) user_info.password = password;
    if (name) user_info.name = name;
    if (phone) user_info.phone = phone;
    try {
      const res = await csrfFetch(`/users`, {
        method: "POST",
        body: JSON.stringify(user_info),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.ok) {
        const result = await res.json();
        console.log("create account response", result);
        dispatch(receiveUser(result));
        dispatch({ type: LOGIN_SUCCESS });
      }
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE, payload: error.message });
    }
  };

const initialState = {
  isChecking: false,
  emailExists: null,
  error: null,
  email: null,
  password: null,
  loggedIn: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_EMAIL_REQUEST:
      return { ...state, isChecking: true, email: action.payload };
    case CHECK_EMAIL_SUCCESS:
      return { ...state, isChecking: false, emailExists: action.payload };
    case CHECK_EMAIL_FAILURE:
      return { ...state, isChecking: false, error: action.payload };
    case LOGIN_REQUEST:
      return { ...state, isChecking: true, password: action.payload };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isChecking: false,
        email: "",
        password: "",
        loggedIn: true,
        emailExists: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isChecking: false,
        error: action.payload,
        email: "",
        password: "",
        emailExists: null,
      };
    default:
      return state;
  }
};

export default authReducer;
