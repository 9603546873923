import React from "react";
import { setAreaFilter, getAreaFilter } from "../../../../store/searchFilters";
import CustomCheckboxes from "../CustomCheckboxes/CustomCheckboxes";

const AreaFilter = () => {
  const AREAS = [
    "Old Town",
    "Near North Side",
    "River North",
    "Streeterville",
    "New East Side",
    "West Loop",
    "Chicago Loop",
    "South Loop",
  ];

  return (
    <CustomCheckboxes
      OPTIONS={AREAS}
      getter={getAreaFilter}
      setter={setAreaFilter}
      label="Neighborhoods"
    />
  );
};

export default AreaFilter;
