import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import SearchIcon from "./SearchIcon";

import { getIsModified } from "../../store/searchFilters";

import "./SplashSearchInput.scss";

const SplashSearchInput = ({
  handleSearchOnChange,
  handleSearchSubmit,
  value,
  term,
  suggestions,
  setSuggestionsBox,
}) => {
  const dispatch = useDispatch();

  const [searchBarClicked, setSearchBarClicked] = useState(false);

  // Handle outside search dropdown menu click. Close dropdown
  // suggestions menu
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (e.target.classList.contains("splash-focused-search")) return;

      document
        .getElementsByClassName("splash-focused-search")[0]
        ?.classList.remove("splash-focused-search");

      setSearchBarClicked(false);
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [searchBarClicked]);

  function handleInputClick(e) {
    e.preventDefault();
    e.stopPropagation();

    e.currentTarget.classList.add("splash-focused-search");
    setSearchBarClicked(true);
  }

  function handleSearchSubmitOnEnter(e) {
    if (e.key === "Enter") {
      handleSearchSubmit(e);
    }
  }

  const is_modified = useSelector(getIsModified());
  const isMobile = window.innerWidth < 600;
  const placeholder = isMobile ? "Apt name" : "Search Chicago apartments";

  return (
    <div>
      <div className="search-input-dropdown-wrapper">
        <div className="splash-search-container" onClick={handleInputClick}>
          <input
            className="search_container__search_bar"
            type="text"
            value={value}
            placeholder={placeholder}
            onChange={handleSearchOnChange}
            onClick={(e) => setSuggestionsBox(true)}
            onKeyDown={handleSearchSubmitOnEnter}
          />
          <div
            className={`search_container__search_button ${
              is_modified ? "modified" : ""
            }`}
            onClick={handleSearchSubmit}
          >
            <SearchIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplashSearchInput;
