import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Navigation from "../Header/Navigation";
import ModalContainer from "../Modal/ModalContainer";
import ListingHeader from "./ListingHeader";
import Gallery from "./gallery";
import Home from "./Home";
import { favouriteUnit } from "../../store/usersReducer";

import { clearDetailedListing } from "../../store/detailedListingReducer";

import "./style/index.scss";

const ShowListing = ({ listing_ts }) => {
  const dispatch = useDispatch();
  const [isCopied, setIsCopied] = useState(false);
  const user_favs = useSelector((state) => state.session.user?.fav_units || []);
  const isFav = user_favs?.includes(listing_ts.web_unit.unit.id);

  const handleCopyLink = async () => {
    try {
      const pageUrl = window.location.href;
      await navigator.clipboard.writeText(pageUrl);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Hide popup after 2 seconds
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const modal_padding = window.innerWidth < 600 ? "0px 16px" : "0px 34px";
  const modalAreaStyling = {
    display: "flex",
    flexDirection: "column",
    width: "1248px",
    height: "100vh",
    backgroundColor: "rgb(255 255 255)",
    padding: modal_padding,
    overflow: "scroll",
  };

  const on_close = () => dispatch(clearDetailedListing());

  const onFav = () => dispatch(favouriteUnit(listing_ts.web_unit.unit.id))

  return (
    listing_ts && (
      <>
        {isCopied && <div className="popup">Link Copied to Clipboard!</div>}
        <ModalContainer modalAreaStyling={modalAreaStyling} on_close={on_close}>
          <Navigation in_modal={true} />
          <ListingHeader onShare={handleCopyLink} on_close={on_close} onFav={onFav} isFav={isFav} />
          <Gallery listing={listing_ts.web_unit} />
          <Home listing_ts={listing_ts} />
        </ModalContainer>
      </>
    )
  );
};

export default ShowListing;
