import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import {
  getSearchResults,
  getSelectedListing,
} from "../../store/searchReducer";
import { receiveDetailedListing } from "../../store/detailedListingReducer";

import { pickListing } from "../../store/searchReducer";
import { getActiveUser } from "../../store/usersReducer";

import UnitListItem from "../UnitListItem/UnitListItem";
import UnitDetails from "../UnitDetails/UnitDetails";
import MapComponent from "../MapComponent/MapComponent";
import InputField from '../BookTourForm/InputField';
import TextArea from '../BookTourForm/TextArea';

import { csrfFetch } from "../../store/csrf";

import "./SearchListPreview.scss";
import "../BookTourForm/BookTourForm.scss";


const validationSchema = Yup.object({
  building: Yup.string().required('Building Name is required'),
  feedback: Yup.string().optional(),
  email: Yup.string().email('Invalid email format').optional(),
});

export const makeBuildingRequest = async (building_form) => {
  try {
    console.log("request building", building_form);
    // const tour = {
    //   building_name: unit.building_name,
    //   unit_numbers: String(unit.unit_number),
    //   unit_ids: unit.id,
    //   tour_availability_dates: Array.from(tour_form.availability)
    //     .map((date_str) => date_str.slice(0, 10))
    //     .join(" "),
    //   email: building_form.email,
    // };
    // console.log("tour", tour);
    let res = await csrfFetch("/forms/building_request", {
      method: "POST",
      body: JSON.stringify(building_form),
    });
  } catch (error) {
    throw error;
  }
};

const AddAUnitForm = () => {
  const currentUser = useSelector(getActiveUser());
  const initialValues = {
    email: currentUser?.email || '',
    building: '',
    feedback: '',
  };

  const handleSubmit = (values) => {
    console.log('Submitting', values);
    makeBuildingRequest(values);
  };

  return (
    <div className="feedback-form-wrapper">
      <div className="user-profile-page-content booking-modal">
        <div style={{ margin: "0 0 8px 0", textAlign: 'center' }}>
          <div className="title">Add a building</div>
        </div>
        <div className="grid-container booking-modal-grid" style={{ justifyContent: 'center' }}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <div className="other-fields-container">
                  <div className='row-container'>
                    <div>
                      <div className="field-wrapper">
                        <div className="labeled-input">
                          <label htmlFor="building" className="mini-label">Building Name</label>
                          <Field id="building" name="building" className="text-input" />
                          <ErrorMessage name="building" component="div" className="error-message" />
                        </div>
                      </div>
                      <div className="field-wrapper">
                        <div className="labeled-input">
                          <label htmlFor="feedback" className="mini-label">Feedback</label>
                          <Field as="textarea" id="feedback" name="feedback" className="text-input" rows="4" />
                          <ErrorMessage name="feedback" component="div" className="error-message" />
                        </div>
                      </div>
                      <div className="field-wrapper">
                        <div className="labeled-input">
                          <label htmlFor="email" className="mini-label">Email (optional)</label>
                          <Field id="email" name="email" type="email" className="text-input" />
                          <ErrorMessage name="email" component="div" className="error-message" />
                        </div>
                      </div>
                      <button type="submit" className="submit-button" style={{ marginLeft: "10px" }}>Submit</button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const SearchListPreview = () => {
  const dispatch = useDispatch();
  const search_listing_results = useSelector(getSearchResults);
  const selected_listing = useSelector(getSelectedListing);
  // useLockBodyScroll();

  const is_mobile = window.innerWidth < 600;
  const on_click_factory = (listing_ts) => () => {
    if (is_mobile) {
      dispatch(receiveDetailedListing(listing_ts));
    }
    dispatch(pickListing(listing_ts));
  };

  const unitRowRefs = useRef([]);
  const containerRef = useRef(null);
  const scrollToElement = (index) => {
    if (unitRowRefs.current[index] && containerRef.current) {
      const container = containerRef.current;
      const element = unitRowRefs.current[index];
      const containerTop = container.getBoundingClientRect().top;
      const elementTop = element.getBoundingClientRect().top;

      container.scrollTo({
        top: elementTop - containerTop + container.scrollTop,
        behavior: 'smooth',
      });
    }
  };
  const on_building_click = (building_name) => {
    const first_match_index = search_listing_results.findIndex(listing => listing && listing.web_unit.unit.building_name === building_name);
    const listing_ts = search_listing_results[first_match_index];
    if (is_mobile) {
      dispatch(receiveDetailedListing(listing_ts));
    }
    dispatch(pickListing(listing_ts));
    scrollToElement(first_match_index);
  };
  const building_locations = search_listing_results.filter(listing => listing).reduce((map, listing) => {
    const { building_name, lat, lon } = listing.web_unit.unit;
    map[building_name] = { lat, lon };
    return map;
  }, {});

  const listing_search = () => (
    <div class="search-results-container">
      <div class="left-component scroll-container" ref={containerRef}>
        <span className="apartments-list-title">
          {" "}
          🏠 Apartments for rent in Chicago
        </span>
        {search_listing_results.map((listing_ts, index) => (
          <UnitListItem
            listing_ts={listing_ts}
            key={index}
            index={index}
            onClick={on_click_factory(listing_ts)}
            isSelected={selected_listing === listing_ts}
            ref={(el) => (unitRowRefs.current[index] = el)}
          />
        ))}
      </div>

      {!is_mobile && (
        <>
          <div class="middle-component">
            <UnitDetails listing_ts={selected_listing} />
          </div>
          <div class="right-component">
            {selected_listing && (
              <MapComponent highlighted_building={selected_listing?.web_unit.unit.building_name} buildings={building_locations} on_click={on_building_click} />
            )}
          </div>
        </>
      )}
    </div>
  );

  return (
    search_listing_results.length === 0 ? <AddAUnitForm /> :
      listing_search()
  );
};

export default SearchListPreview;
