import React from 'react';
import { Helmet } from "react-helmet";

import './AboutPage.scss'; // Import the CSS file
import Navigation from "../Header/Navigation";
import Footer from "../Footer";

const AboutContent = () => {
    return (
        <div className='about'>
            {/* Header section */}
            <div className="header">
                <h1 className='title'>A smarter way to rent</h1>
            </div>

            {/* Main content */}
            <div className="content">
                <div className='row'>
                    <div class="text-wrapper">
                        <h2>Apartment prices change daily. <br /> RENT-ARB helps you buy at the bottom not the top.</h2>
                    </div>
                </div>

                {/* Centered images and text */}
                <div className="centered-content">
                    <div className="image-container">
                        <div className='image-wrapper' style={{
                            borderWidth: '1px',
                            borderColor: 'black'
                        }}>
                            <img src="/high.svg" alt="rent_img" style={{
                                transform: 'scale(1.2) translate(0, -60px)'
                            }} />
                            <span class='img-label'>Don't buy here</span>
                        </div>
                        {/* <img src="/73_rent_clean.png" alt="rent_img" /> */}
                        {/* <img src="https://via.placeholder.com/400x300" alt="Company Image 2" /> */}
                    </div>
                    <div className="image-container">
                        <div className='image-wrapper' style={{
                            borderWidth: '1px',
                            borderColor: 'black'
                        }}>
                            <img src="/low.svg" alt="rent_img" style={{
                                transform: 'scale(1.2) translate(0, 40px)'
                            }} />
                            <span class='img-label'>Buy here</span>
                        </div>

                    </div>
                </div>

                <div className='row'>
                    <div class="text-wrapper">
                        <h2>Rent prices can move by hundreds of dollars a week. Which means you can save thousands of dollars with RENT-ARB, for free!</h2>
                    </div>
                </div>

            </div>
        </div>
    );
};

const AboutPage = () => {
    return (
        <>
            <Helmet>
                <title>A smarter way to rent | Rent-Arb</title>
            </Helmet>
            <Navigation />
            <AboutContent />
            <Footer />
        </>
    );
}

export default AboutPage;
