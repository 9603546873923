import React, { useState, useRef, useEffect } from "react";
import "./Featured.scss";
import { getFeatured } from "../../store/featuredReducer";
import UnitHeader from "../UnitHeader/UnitHeader";
import PlotlyComponent from "../PriceGraph/PriceGraph";
import { receiveDetailedListing } from "../../store/detailedListingReducer";
import { useDispatch, useSelector } from "react-redux";
import FeaturedSkeleton from "./FeaturedSkeleton";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Ensure Swiper styles are imported
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Initialize Swiper modules if you haven't done so
import SwiperCore, { Navigation, Pagination } from 'swiper';
SwiperCore.use([Navigation, Pagination]);


const CategorySelector = ({ categories, selected_category, set_selected_index }) => {
  const pink = 'rgba(217, 0, 72, 1)';
  return (
    <div className="category-selector">
      <div className="category-selector-wrapper">
        {
          categories.map((area, i) => {
            return (
              <button className="category-button" onClick={() => set_selected_index(i)} key={i}>
                <span className="category-span" style={{ color: area == selected_category ? pink : 'black' }}> {area} </span>
              </button>
            );
          })
        }
      </div>
    </div>
  );
}


const InteractivePricePlot = ({ featured_unit }) => {
  const web_unit = featured_unit.web_unit;
  const rent_ts = featured_unit.rent_ts;
  const dates = featured_unit.dates;
  const isMobile = window.innerWidth < 600;
  let layout_overrides = {};
  if (isMobile) {
    layout_overrides = {
      width: window.innerWidth + 40,
    };
  }


  return (
    <div className="price-plot">
      <UnitHeader web_unit={web_unit} className="swiper-no-swiping" />
      <div className="price-plot-container">
        <div className="price-plot-separator"></div>
        <div className="price-plot-graph">
          <div className="plot-img-wrapper">
            {/* <img src={rent_image} alt="Price plot"/> */}
            {/* <svg src={rent_svg} alt="Price plot"/> */}
            {/* <RentSvg /> */}
            <PlotlyComponent x={dates} y={rent_ts} layout_overrides={layout_overrides} />
          </div>
        </div>
      </div>
    </div>
  );
}

const FeaturedSlide = ({ featured }) => {
  const dispatch = useDispatch();

  if (!featured) {
    return <div></div>;
  }

  const showListing = () => {
    dispatch(receiveDetailedListing(featured))
  }

  return (
    <div className="slide-container">
      <a className="slide-container-link">
        <div className="slide-apartment-image" onClick={showListing}>
          <img src={featured.web_unit.photo_urls[0]} />
        </div>
        <InteractivePricePlot featured_unit={featured} />
      </a>
    </div>
  );
}

const Featured = () => {
  const featured = useSelector(getFeatured);
  const num_featured = 7;
  const categories = Object.keys(featured).slice(0, num_featured);
  const [selected_index, set_selected_index] = useState(0);
  const swiperRef = useRef(null);

  const goToSlide = (index) => {
    set_selected_index(index);
    if (swiperRef.current) {
      swiperRef.current.slideToLoop(index);
    } else {
      console.error('Swiper not initialized');
    }
  };

  return (
    <>
      <div className="wrapper-splash">
        <CategorySelector categories={categories} selected_category={categories[selected_index]} set_selected_index={goToSlide} />
        {/* <FeaturedSlide featured={featured[categories[selected_index]]} /> */}
        <Swiper
          onSwiper={(swiper) => (swiperRef.current = swiper)} // Assign Swiper instance to ref
          slidesPerView={1}
          onSlideChange={(swiper) => { console.log('setting index', swiper.activeIndex); set_selected_index(swiper.realIndex) }}
          loop={true}
        >
          {categories.length ?
            categories.map((category, id) =>
            (<SwiperSlide key={id}>
              <FeaturedSlide featured={featured[category]} />
            </SwiperSlide>)
            )
            : [1].map((_, id) => (
              <SwiperSlide key={id}>
                <FeaturedSkeleton key={id} />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </>
  );
}

export default Featured;
