import { extendTheme } from "@chakra-ui/react";

const tabListTheme = extendTheme({
  components: {
    Tabs: {
      baseStyle: {
        tab: {
          _selected: {
            color: "black",
            borderColor: "#d90048",
            borderBottom: "4px solid #d90048",
            borderRadius: "4px",
          },

          _hover: {
            color: "#d90048",
          },
        },
      },
    },
  },
});

export default tabListTheme;
