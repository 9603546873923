import { csrfFetch } from "./csrf";
import { objectToQuerySting } from "./utils";
import {
  cleanLocalStorageSearchCredentials,
  getLocalStorageSearchCredentials,
} from "./utils";
import { createSelector } from "reselect";

const searchResultSelector = (state) => state.searchResults;

const RECEIVE_SEARCH_RESULTS = "api/search/RECEIVE_SEARCH_RESULTS";
const CLEAR_SEARCH_RESULTS = "api/search/CLEAR_SEARCH_RESULTS";
const PICK_LISTING = "api/search/PICK_LISTING";

export const receiveSearchResults = (searchResults) => ({
  type: RECEIVE_SEARCH_RESULTS,
  searchResults,
});

const DEFAULT_OBJECT = {
  selected_listing: null,
  results: [],
};

const clearSearchResults = () => ({
  type: CLEAR_SEARCH_RESULTS,
});

export const getSearchResults = createSelector(
  [searchResultSelector],
  (searchResults) => {
    if (searchResults && searchResults.results) {
      return searchResults.results;
    }

    return [];
  }
);

export const getSelectedListing = createSelector(
  [searchResultSelector],
  (searchResults) => {
    if (searchResults.selected_listing) {
      return searchResults.selected_listing;
    }
    return null;
  }
);

export function parseBuildingAndUnit(inputString) {
  if (!inputString) {
    return { building: undefined, unit_number: undefined };
  }
  // Regular expression to match the unit number preceded by a #
  const regex = /#\d+$/;

  // Find the unit number using the regular expression
  const unitMatch = inputString.match(regex);
  let building, unit_number;

  if (unitMatch) {
    // If a unit number is found, extract it and the building name
    unit_number = parseInt(unitMatch[0].substring(1), 10); // Remove the '#' and convert to an integer
    building = inputString
      .substring(0, inputString.indexOf(unitMatch[0]))
      .trim();
  } else {
    // If no unit number is found, the whole string is the building name
    building = inputString.trim();
    unit_number = undefined;
  }

  return { building, unit_number };
}

export const fetchSearchResultsHelper = async (
  action,
  dispatch,
  search_string = "",
  baseParams = {}
) => {
  const queryParams = { ...baseParams };
  const min_price =
    queryParams.minPrice && Number(queryParams.minPrice.replace(/,/g, ""));
  const max_price =
    queryParams.maxPrice && Number(queryParams.maxPrice.replace(/,/g, ""));
  let { building, unit_number } = parseBuildingAndUnit(search_string);
  const transformedQueryParams = {
    min_price,
    max_price,
    min_sqft: queryParams.squareFootFilter,
    max_days_till_available: queryParams.availabilityFilter,
    min_bed: queryParams.bedroom,
    min_bath: queryParams.bathroom,
    building: building,
    unit_number: unit_number,
    cap_results: queryParams.cap_results,
    group_building_results: queryParams.building_cap || 5,
    sort_by: queryParams.sort_by,
  };
  console.log("transformedQueryParams", transformedQueryParams);

  const params = new URLSearchParams();
  Object.entries(transformedQueryParams).forEach(([key, value]) => {
    if (value !== undefined) {
      params.append(key, value);
    }
  });
  queryParams.areas?.forEach((item) => params.append("required_areas", item));
  queryParams.excludes?.forEach((amenity) => {
    const key = amenity.replace(" ", "_").toLowerCase();
    params.append(key, true);
  });
  const queryString = params.toString();
  const res = await csrfFetch(`/api/search?${queryString}`);
  if (res.ok) {
    const searchResults = await res.json();
    dispatch(action(searchResults));
  }
};

export const fetchSearchResults =
  (search_string = "") =>
  async (dispatch) => {
    localStorage.setItem("search_string", search_string);
    const baseParams = getLocalStorageSearchCredentials();
    fetchSearchResultsHelper(
      receiveSearchResults,
      dispatch,
      search_string,
      baseParams
    );
  };

export const pickListing = (listing) => async (dispatch) => {
  dispatch({ type: PICK_LISTING, listing });
};

export const clearAllSearchResults = () => async (dispatch) => {
  dispatch(clearSearchResults());
};

const searchResultsReducer = (state = DEFAULT_OBJECT, action) => {
  const newState = { ...state };

  switch (action.type) {
    case RECEIVE_SEARCH_RESULTS:
      if (action.searchResults) {
        return {
          ...newState,
          results: action.searchResults,
          selected_listing: action.searchResults[0],
        };
      }
      return state;
    case PICK_LISTING:
      return { ...newState, selected_listing: action.listing };
    case CLEAR_SEARCH_RESULTS:
      return {};
    default:
      return state;
  }
};

export default searchResultsReducer;
