import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y } from "swiper";
import { CarouselNextButton, CarouselPrevButton } from "./CarouselButton";

import ListingItem from "../ListingItem/ListingItem";

import "./Carousel.scss";

import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import ListingItemSkeleton from "./LisitngItemSkeleton";

const Carousel = ({
  sampleListings,
  prevButtonClassName,
  nextButtonClassName,
  header,
  paragraph,
}) => {
  const listingStyling = {
    minWidth: "286px",
    height: "290px",
  };

  return (
    <div className="carousel-container">
      <div className="header-container">
        <div className="splat_listing_container__headers">
          <h1>{header}</h1>
          <p>{paragraph}</p>
        </div>
        <div className="custom-nav-buttons">
          <CarouselPrevButton prevButtonClassName={prevButtonClassName} />
          <CarouselNextButton nextButtonClassName={nextButtonClassName} />
        </div>
      </div>

      <div style={{ width: "100%" }}>
        <Swiper
          className="carousel-container__swiper"
          modules={[Navigation, A11y]}
          spaceBetween={25}
          slidesPerView={1}
          navigation={{
            prevEl: `.${prevButtonClassName}`,
            nextEl: `.${nextButtonClassName}`,
          }}
          breakpoints={{
            // when window width is >= 640px
            640: {
              slidesPerView: 2.4,
              spaceBetween: 20,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 3.4,
              spaceBetween: 30,
            },
          }}
        >
          {sampleListings.length
            ? sampleListings.map((listing, i) => {
              return (
                <SwiperSlide key={i}>
                  <ListingItem
                    listing_ts={listing}
                    listingStyling={listingStyling}
                    index={i}
                  />
                </SwiperSlide>
              );
            })
            : [1, 2, 3, 4, 5].map((_, idx) => (
              <SwiperSlide key={idx.id}>
                <ListingItemSkeleton key={idx} />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Carousel;
