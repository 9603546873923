import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import UserOptionsContainer from "./components/UserProfile/UserOptionsContainer";
import SplashPage from "./components/Splash/SplashPage";
import IndexPage from "./components/IndexPage/IndexPage";
import ContactPage from "./components/ContactPage/ContactPage";
import AboutPage from "./components/about/AboutPage";

import "./index.scss";
import { SkeletonTheme } from "react-loading-skeleton";

import { useEffect } from "react";
import { fetchCurrentUser } from "./store/usersReducer";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import NewRenterPage from "./components/NewRenter/NewRenterPage";

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <meta
          property="og:description"
          content="We help renters lease when prices are low by providing rent data over time across apartments in Chicago."
        />
        <meta name="language" content="en" />
        <meta http-equiv="language" content="en" />
        <meta http-equiv="content-language" content="en-US" />
        <meta property="og:site_name" content="Rent-Arb" />
        <meta property="mobile-web-app-capable" content="yes"></meta>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website"></meta>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-QSXGLJ4ZC4"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-QSXGLJ4ZC4');
          `}
        </script>
      </Helmet>
      <SkeletonTheme baseColor="#eaeaea" highlightColor="#d9d9d9">
        <ScrollToTop />
        <Routes>
          <Route path="/user/:id" element={<UserOptionsContainer />} />
          <Route path="/" element={<SplashPage />} />
          <Route path="/listings/" element={<IndexPage />} />
          <Route
            path="/listing/:listing_id"
            element={<SplashPage key={window.location.pathname} />}
          />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/new_renters" element={<NewRenterPage />} />
        </Routes>
      </SkeletonTheme>
    </>
  );
};

export default App;
