import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { BuildingPriceGraphComponent, MultiPriceGraph, getDatasetSelected, getDatasetPriceMove } from "../PriceGraph/PriceGraph";

import { Info, QuestionMark } from "./assets/svgs";

import ModalUnitDetails from "./ModalUnitDetails";
import ModalBuildingDetails from "./ModalBuildingDetails";
import MapComponent from "../MapComponent/MapComponent";
import UnitListItem from "../UnitListItem/UnitListItem";
import ModalContainer from "../Modal/ModalContainer";
import BookTourForm from "../BookTourForm/BookTourForm";
import ConfettiBurst from "../Confetti/ConfettiBurst";

import {
  fetchRelatedResults,
  fetchRelatedBuildingsResults,
  getRelatedResults,
  getRelatedBuildingsResults,
  fetchBuildingResults,
  clearAllRelatedResults,
} from "../../store/relatedUnitsReducer";
import {
  getSearchResults,
} from "../../store/searchReducer";
import { getListings } from "../../store/listingsReducer";
import { receiveDetailedListing } from "../../store/detailedListingReducer";
import { startBooking, endBooking } from "../../store/tourReducer";
import {
  pickDetailedListing,
} from "../../store/detailedListingReducer";

import "./style/home.scss";

const BookTourModal = ({ showBookTour, on_close }) => {

  let modalAreaStyling = {
    display: "flex",
    flexDirection: "column",
    padding: "27px 16px 14px 16px",
    borderRadius: "10px",
    width: "650px",
    backgroundColor: "rgb(255 255 255)",
    marginLeft: "-150px",
  };
  const isMobile = window.innerWidth < 600;
  if (isMobile) {
    modalAreaStyling = {
      ...modalAreaStyling,
      display: "flex",
      flexDirection: "column",
      padding: "27px 16px 14px 16px",
      borderRadius: "10px",
      width: "100%",
      backgroundColor: "rgb(255 255 255)",
      marginLeft: "0",
    };
  }

  return (
    <>
      {showBookTour && (
        <ModalContainer style={{ zIndex: 1 }} modalAreaStyling={modalAreaStyling} on_close={on_close}>
          <BookTourForm />
        </ModalContainer>
      )}
    </>
  );

}

const Home = ({ listing_ts }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const relatedListings = useSelector(getRelatedResults);
  const relatedBuildingListings = useSelector(getRelatedBuildingsResults);
  const { isBooking, celebrate } = useSelector((state) => state.tours);
  const is_building = listing_ts.web_unit.unit.unit_sqr_ft === 0;
  useEffect(() => {
    const isMobile = window.innerWidth < 600;
    const num_results = isMobile ? 10 : 20;
    if (is_building) {
      dispatch(fetchBuildingResults(listing_ts.web_unit.unit, num_results));
    } else {
      dispatch(fetchRelatedResults(listing_ts.web_unit.unit, num_results));
      dispatch(fetchRelatedBuildingsResults(listing_ts.web_unit.unit, num_results));
    }
    return () => {
      clearAllRelatedResults();
    };
  }, [dispatch, listing_ts]);
  const [isShowBookTourPopup, setIsShowBookTourPopup] = useState(false);
  const [isExitAnimation, setExitAnimation] = useState(false);
  const [showCat, setShowCat] = useState(false);
  const [showCelebrateCat, setShowCelebrateCat] = useState(false);
  // const [showBookTour, setShowBookTour] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    if (isBooking) {
      setShowCelebrateCat(true);
      console.log('is booking', isBooking);
    } else {
      setShowCelebrateCat(false);
    }
  }, [dispatch, isBooking]);

  useEffect(() => {
    if (celebrate) {
      setShowConfetti(true);
      setTimeout(() => {
        setShowConfetti(false);
      }, 5000);
    }
  }, [celebrate, dispatch]);

  const search_listings = useSelector(getSearchResults);
  const daily_listings = useSelector(getListings);

  if (!relatedListings.some(listing => listing.web_unit.unit.id === listing_ts.web_unit.unit.id)) {
    relatedListings.push(listing_ts);
  }

  const known_listings = search_listings.concat(daily_listings).concat(relatedListings).concat(relatedBuildingListings);
  const building_locations = known_listings.filter(Boolean).reduce((map, listing) => {
    const { building_name, lat, lon } = listing.web_unit.unit;
    map[building_name] = { lat, lon };
    return map;
  }, {});

  const on_building_click = (building_name) => {
    // const building_url = `/listing/${building_name.replaceAll("-", "_").replaceAll(" ", "_")}`;
    // navigate(building_url);
    const first_match_index = known_listings.findIndex(listing => listing && listing.web_unit.unit.building_name === building_name);
    const listing_ts = known_listings[first_match_index];
    // pickDetailedListing(listing_ts);
    dispatch(receiveDetailedListing(listing_ts))
  }


  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });

  const listing = listing_ts.web_unit;
  listing.favorite = false;
  const unit = listing.unit;
  unit.address = unit.address.replace(",,", ",");

  const price = formatter.format(unit.unit_price);
  // const alpha = (Math.random() * 5 - 3) * 100; //Math.max(unit.alpha || 0, 0);
  function findMaxFloatKey(obj) {
    return Object.entries(obj).reduce((acc, [key, value]) => value > acc[1] ? [key, value] : acc, ['', -Infinity])[0];
  }
  const optimistic_model = findMaxFloatKey(listing.expected_prices)
  const alpha = unit.unit_price - listing.expected_prices[optimistic_model];
  let model_group = optimistic_model;
  if (model_group === 'chi') {
    model_group = 'city';
  }
  const fair_price_for_area = `Fair price for a unit like this for the ${model_group} is ${formatter.format(listing.expected_prices[optimistic_model])}`;

  // const dollar_signs = " $".repeat(alpha / 100 + 3);
  const jared_score = `${formatter.format(alpha)} ${alpha > 0 ? "over" : "under"
    } priced`;

  let price_graph_layout = {
    width: 700,
    height: 400,
    showlegend: false,
    dragmode: is_building,
    hovermode: 'closest',
  };

  let price_comparision_graph_layout = {
    width: 1000,
    height: 400,
    showlegend: false,
    dragmode: false,
    margin: {
      l: 70, // Left margin
      r: 80, // Right margin
      b: 100, // Bottom margin
      t: 0, // Top margin
      pad: 4 // Padding between the plotting area and the axis lines
    },
  };

  const isMobile = window.innerWidth < 600;
  if (isMobile) {
    price_graph_layout = {
      ...price_graph_layout,
      width: window.innerWidth + 40,
      height: 200,
    };

    price_comparision_graph_layout = {
      ...price_comparision_graph_layout,
      width: window.innerWidth + 40,
      height: 350,
      margin: {
        l: 70, // Left margin
        r: 90, // Right margin
        b: 150, // Bottom margin
        t: 0, // Top margin
        pad: 4 // Padding between the plotting area and the axis lines
      },
      xaxis: {
        tickangle: -90, // Make x-axis labels vertical
      }
    }
  }

  const area_link = `/area/${unit.area.replace(" ", "_")}`;
  const is_available = listing_ts.rent_ts.slice(-1)[0] || is_building;

  const showUnavailable = (event) => {
    event.preventDefault();
    event.stopPropagation();
    alert(`Sorry 😕 this unit is not available anymore to rent.
But you can check the RELATED APARTMENTS below for other units in the building!`);
  }

  const openNewTab = (event) => {
    const isFirstClick = !sessionStorage.getItem("bookTourPopupShown");
    if (isFirstClick) {
      sessionStorage.setItem("bookTourPopupShown", true);
      setIsShowBookTourPopup(true);
      setShowCat(true);
      setShowCelebrateCat(false);
      setTimeout(() => setExitAnimation(true), 9000);
      setTimeout(() => setShowCat(false), 9000);
      setTimeout(() => setIsShowBookTourPopup(false), 10000); // Hide popup after 30 seconds
      setTimeout(() => setExitAnimation(false), 10000);
      setTimeout(
        () => sessionStorage.removeItem("bookTourPopupShown"),
        30 * 60 * 1000
      );
    } else {
      if (event.ctrlKey || event.button === 1 || event.button === 0) {
        window.open(listing.building_website, "_blank", "noopener,noreferrer");
      }
    }
  };

  const onBookTourClick = () => {
    setShowCat(false);
    setShowCelebrateCat(true);
    // setTimeout(() => setShowCelebrateCat(false), 10000);
    // setShowBookTour(true)
    dispatch(startBooking());
  };

  const closeBookTour = () => {
    // setShowBookTour(false);
    dispatch(endBooking());
    setShowCelebrateCat(false);
  };

  const datasets = is_building ? getDatasetPriceMove(relatedListings) : getDatasetSelected(relatedListings, listing.unit.id);
  const config_overrides = is_building ? { dragMode: true } : { dragMode: false };

  const handleRelatedUnitClick = (event) => {
    console.log(event);
    const { points } = event;
    if (points.length > 0) {
      const point = points[0];
      const unit_price = point.y;
      const date = point.x;
      const selected_listing = relatedListings.find(listing => {
        const x_index = listing.dates.indexOf(date);
        return listing.rent_ts[x_index] === unit_price;
      });
      console.log(`Price: ${unit_price}`);
      console.log(selected_listing);
      if (!selected_listing) {
        return;
      }

      if (event.event.ctrlKey || event.event.metaKey || event.event.button === 1) {
        const unit_id = selected_listing.web_unit.unit.id;
        const listing_website = `/listing/${unit_id}`;
        window.open(listing_website, '_blank');
        return;
      }

      dispatch(receiveDetailedListing(selected_listing));
    }
  };

  const RelatedApartments = () => (
    <div className="related-units-container">
      <div className="left-component scroll-container">
        <span className="home-subtitle"> 🏠 Related apartments</span>
        {relatedListings.slice(0, 30).map((unit_ts, index) => (
          <UnitListItem
            listing_ts={unit_ts}
            key={index}
            index={index}
            onClick={() => dispatch(receiveDetailedListing(unit_ts))}
            isSelected={unit_ts.web_unit.unit.id === listing_ts.web_unit.unit.id}
          />
        ))}
      </div>
      <div className="right-component">
        <span class="home-subtitle"> 💸 Rent history </span>
        <MultiPriceGraph datasets={datasets} layout_overrides={price_graph_layout} config_overrides={config_overrides} onClick={handleRelatedUnitClick} />
        {/* <PlotlyComponent
        x={listing_ts.dates}
        y={listing_ts.rent_ts}
        layout_overrides={price_graph_layout}
      /> */}
      </div>
    </div>
  );


  const BuildingUnitComparision = () => {
    const current_building = listing.unit.building_name;
    if (!relatedBuildingListings.some(listing => listing.web_unit.unit.building_name === current_building)) {
      relatedBuildingListings.push(listing_ts);
    }
    const sorted_building_units = relatedBuildingListings.map(listing => listing.web_unit.unit).sort((a, b) => a.unit_price - b.unit_price);
    const building_names = sorted_building_units.map(unit => unit.building_name);
    const unit_prices = sorted_building_units.map(unit => unit.unit_price);
    const unit_sqr_ft = sorted_building_units.map(unit => `${unit.unit_sqr_ft} sqft`);

    const handlePointClick = (event) => {
      const { points } = event;
      if (points.length > 0) {
        const point = points[0];
        const building = point.x;
        const selected_listing = relatedBuildingListings.find(listing => listing.web_unit.unit.building_name === building);
        console.log(`Building: ${building}, Price: ${price}`);
        console.log(selected_listing);

        if (event.event.ctrlKey || event.event.metaKey || event.event.button === 1) {
          const unit_id = selected_listing.web_unit.unit.id;
          const listing_website = `/listing/${unit_id}`;
          window.open(listing_website, '_blank');
          return;
        }

        dispatch(receiveDetailedListing(selected_listing));
      }
    };
    return (
      <div className="related-units-container">
        <div className="right-component">
          <span class="home-subtitle"> 👯 Similar units in other buildings </span>
          <BuildingPriceGraphComponent
            x={building_names}
            y={unit_prices}
            text={unit_sqr_ft}
            highlight_x={current_building}
            highlight_y={listing.unit.unit_price}
            layout_overrides={price_comparision_graph_layout}
            onPointClick={handlePointClick}
          />
        </div>
      </div>
    )
  };

  const [show_detailed_score, set_show_detailed_score] = useState(false);
  const toggleDetailedReview = () => { set_show_detailed_score(!show_detailed_score); };
  function capitalizeFirstLetter(str) {
    if (!str) return str; // Return the original string if it's empty or undefined
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <div className="home-container">
      {/* {showConfetti && <Confetti />} */}
      <ConfettiBurst trigger={showConfetti} />
      <BookTourModal showBookTour={isBooking} on_close={closeBookTour} />

      <div className="left-pane">
        <div className="listing-details-wrapper">
          <div className="top-container">
            <div className="left-component">
              {is_building ? <ModalBuildingDetails listing={listing} /> : <ModalUnitDetails listing={listing} is_available={is_available} />}
            </div>
            <div className="right-component">
              <div className="right-side-container">
                <div className="follow-buttons-wrapper">
                  {is_available ? <button className="book_tour_btn" onClick={onBookTourClick}>
                    Book Tour
                  </button>
                    :
                    <button className="book_tour_btn unavailable" onClick={showUnavailable}>
                      Book Tour
                    </button>
                  }
                  <img
                    src="/cat_orange_wave.png"
                    alt=""
                    className={`cat-emoji ${showCat ? "slide-in" : "slide-out"
                      }`}
                  />
                  <img
                    src="/cat_orange_celebrate.png"
                    alt=""
                    className={`cat-emoji ${showCelebrateCat ? "slide-in" : "slide-out"
                      }`}
                  />
                  <button
                    className="building_website_btn"
                    onMouseDown={openNewTab}
                    onClick={(event) => event.preventDefault()}
                  // onClick={() => openNewTab(listing.building_website)}
                  >
                    Building Website
                  </button>
                  {isShowBookTourPopup && (
                    <div
                      className={`popup booking_popup ${isExitAnimation ? " exit_popup" : " intro_popup"
                        }`}
                    >
                      {/* Hey! Thinking of leasing this unit? Do us a favor and book
                      through us. It's free and we'll get a small commission
                      from the building! */}
                      Hey! Thinking of leasing this unit? Do us a favor and mention us when you tour!
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {!is_building && <div class="listing-price-graph-wrapper">
            <div className="est-payment-container">
              <div className="est-payment" onClick={toggleDetailedReview}>
                Jared's Score: &nbsp;
                {/* <span class="jscore_dollars">{dollar_signs}</span>,  */}
                {jared_score}&nbsp;
                <Info on_hover_text={fair_price_for_area} />
              </div>
            </div>

            {show_detailed_score &&
              <div className="jared-score-more-info">
                <div class='wrapper'>
                  <span className="main-label">Fair price for a unit like this in the...</span>
                  {
                    Object.entries(listing.expected_prices).map(([model, price]) => <span className={`bullet ${optimistic_model == model && 'featured'}`}> {model == 'chi' ? 'City' : capitalizeFirstLetter(model)} is {formatter.format(price)} </span>)
                  }
                </div>
              </div>
            }
          </div>}
        </div>

        <RelatedApartments />
        <BuildingUnitComparision />

        <div className="show-page-map-container">
          <MapComponent highlighted_building={listing.unit.building_name} buildings={building_locations} on_click={on_building_click} />
        </div>
      </div>
    </div>
  );
};

export default Home;
