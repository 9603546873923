import Navigation from "../Header/Navigation";
import Footer from "../Footer";
import UserProfileTabs from "./UserProfileTabs";

import "./UserOptionsContainer.scss";

const UserOptionsContainer = () => {
  return (
    <>
      <Navigation />
      <UserProfileTabs />
      <Footer />
    </>
  );
};

export default UserOptionsContainer;
