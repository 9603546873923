import React, { useRef, useEffect } from 'react';
import confetti from 'canvas-confetti';

const ConfettiBurst = ({ trigger }) => {
    const confettiRef = useRef();

    useEffect(() => {
        if (trigger) {
            const myConfetti = confetti.create(confettiRef.current, {
                resize: true,
                useWorker: true,
            });
            myConfetti({
                particleCount: 100,
                spread: 160,
                angle: 90,
                startVelocity: 40,
                origin: { y: 0.6 }, // Adjust to make the burst come from different locations
            });
        }
    }, [trigger]);

    return <canvas ref={confettiRef} style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', pointerEvents: 'none', zIndex: 9 }} />;
};

export default ConfettiBurst;
