import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getActiveUser, updateUserInfo } from "../../store/usersReducer";

import "./UserProfile.scss";

function extractPhoneNumber(phoneNumberString) {
	const nonNumericCharacters = /\D/g;
	const numericPhoneNumber = phoneNumberString.replace(nonNumericCharacters, '');
	return numericPhoneNumber;
}

const UserProfile = ({ currentUser }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [userInfo, setUserInfo] = useState({
		email: currentUser?.email || '',
		name: currentUser?.name || '',
		phone: currentUser?.phone || '',
		sub_updates: currentUser?.sub_updates || true,
		sub_new_flats: currentUser?.sub_new_flats || true,
	});
	const [isModified, setIsModified] = useState(false);
	const [editMode, setEditMode] = useState({ email: false, password: false });

	// Handlers for entering edit modes
	const enterEditMode = (field) => {
		setEditMode({ ...editMode, [field]: true });
	};

	useEffect(() => {
		if (!currentUser || Object.keys(currentUser).length === 0) {
			navigate("/");
			return;
		}
		setUserInfo({
			email: currentUser.email || '',
			name: currentUser.name || '',
			phone: currentUser.phone || '',
			sub_updates: currentUser.sub_updates,
			sub_new_flats: currentUser.sub_new_flats,
		});
	}, [navigate, currentUser]);

	const handleInputChange = (e) => {
		let { name, value } = e.target;
		if (name === 'phone') {
			value = extractPhoneNumber(value);
		}
		if (e.target.type === 'checkbox') {
			value = !userInfo[name];
		}
		setUserInfo((prevState) => ({
			...prevState,
			[name]: value,
		}));
		setIsModified(true);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(updateUserInfo(userInfo));
		setIsModified(false);
		cancelEdit();
	};

	const cancelEdit = () => {
		setEditMode({ email: false, password: false });
	}

	const unsubscribeAll = () => {
		const sub_updates = { name: 'sub_updates', value: false };
		const sub_new_flats = { name: 'sub_new_flats', value: false };
		handleInputChange({ target: sub_updates });
		handleInputChange({ target: sub_new_flats });
	}

	return (
		<div className="user-profile-page-content">
			<div style={{ margin: "0 0 8px 0" }}>
				<div className="title">
					Edit Profile
				</div>
			</div>
			<div className="grid-container">
				<div width="1" className="outer-wrapper">
					<div className="form-padding">
						<form onSubmit={handleSubmit}>


							{(!editMode.email && !editMode.password) && (
								<div className="grid-cell-wrapper">
									<div width="1" className="field-wrapper">
										<label>
											<strong>Email</strong>
										</label>
										<div className="text-field">
											{userInfo.email}
											<div role="button" className="edit-button" tabIndex="0" onClick={() => enterEditMode("email")}>
												<div color="highlight" className="edit-hightlight">Edit</div>
											</div>
										</div>
									</div>
									<div width="1" className="field-wrapper">
										<label>
											<strong>Password</strong>
										</label>
										<div data-testid="user-profile-password" className="text-field">
											******
											<div role="button" className="edit-button" tabIndex="0" onClick={() => enterEditMode("password")}>
												<div color="highlight" className="edit-hightlight">Edit</div>
											</div>
										</div>
									</div>
								</div>
							)}
							{editMode.email && (
								<>
									<div className="other-fields-container">
										<div width="1" className="field-wrapper">
											<div className="labeled-input">
												<label htmlFor="email" className="mini-label">New Email</label>
												<input id="email" type="email" name="email" className="text-input" defaultValue={userInfo.email} onChange={handleInputChange} />
											</div>
										</div>
										<div width="1" className="field-wrapper">
											<div className="labeled-input">
												<label htmlFor="current_password" className="mini-label">Current Password</label>
												<input id="current_password" type="password" name="current_password" className="text-input" defaultValue="" onChange={handleInputChange} />
											</div>
										</div>
									</div>
									<button type="reset" className="submit-button" onClick={cancelEdit}>Cancel</button>
								</>
							)}
							{editMode.password && (
								<>
									<div className="other-fields-container">
										<div width="1" className="field-wrapper">
											<div className="labeled-input">
												<label htmlFor="current_password" className="mini-label">Current Password</label>
												<input id="current_password" type="password" name="current_password" className="text-input" defaultValue="" onChange={handleInputChange} />
											</div>
										</div>
										<div width="1" className="field-wrapper">
											<div className="labeled-input">
												<label htmlFor="password" className="mini-label">New Password</label>
												<input id="password" type="password" name="password" className="text-input" defaultValue="" onChange={handleInputChange} />
											</div>
										</div>
									</div>
									<button type="reset" className="submit-button" onClick={cancelEdit}>Cancel</button>
								</>
							)}



							<div className="other-fields-container">
								<div width="1" className="field-wrapper">
									<div className="labeled-input">
										<label htmlFor="name" className="mini-label">Full Name</label>
										<input id="name" type="text" name="name" className="text-input" defaultValue={userInfo.name} onChange={handleInputChange} />
									</div>
								</div>
								<div width="1" className="field-wrapper">
									<div className="labeled-input">
										<label htmlFor="phone" className="mini-label">Phone</label>
										<input id="phone" type="tel" name="phone" className="text-input" defaultValue={userInfo.phone} onChange={handleInputChange} />
									</div>
								</div>
							</div>

							<div className="email-notification-preferences">
								<label>
									<strong>Email notifications</strong>
								</label>
								<div className="preferences">
									<label>
										<input
											type="checkbox"
											name="sub_updates"
											checked={userInfo.sub_updates}
											onChange={handleInputChange}
										/>
										Get updates when my favorites drop in price or leave the market
									</label>
									<label>
										<input
											type="checkbox"
											name="sub_new_flats"
											checked={userInfo.sub_new_flats}
											onChange={handleInputChange}
										/>
										Let me know when new apartments match my search
									</label>
									<label>
										<div role="button" className="edit-button" style={{ marginLeft: 0 }} onClick={unsubscribeAll}>
											<div color="highlight" className="edit-hightlight">Unsubscribe from rental notifications</div>
										</div>
									</label>
								</div>
							</div>

							<button type="submit" disabled={!isModified} className="submit-button">Save Profile</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserProfile;
