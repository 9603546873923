import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import ShowListing from "../ShowListing/index";
import {
  getDetailedListing,
} from "../../store/detailedListingReducer";
import {
  fetchSearchResults,
} from "../../store/searchReducer";
import useLockBodyScroll from "./useLockScroll";
import Navigation from "../Header/Navigation";
import FilterButtons from "../SearchBar/FilterButtons/FilterButtons";

import SearchListPreview from "../SearchListPreview/SearchListPreview";

import "./IndexPage.scss";

const IndexPage = () => {
  const dispatch = useDispatch();
  const detailed_listing_state = useSelector(getDetailedListing);
  const location = useLocation();
  const search_string = location.state?.search_string || "";
  useEffect(() => {
    const search_string = location.state?.search_string || "";
    dispatch(fetchSearchResults(search_string));
  }, [dispatch, location.state]);

  useLockBodyScroll();

  return (
    <>
      <Helmet>
        <title>Search Apartment Rent Prices in Chicago | Rent-Arb</title>
        <meta
          name="description"
          content="Find out if the apartments you're interested in are going up or down in price. Make an informed guess on how long they'll stay on the market."
        />
      </Helmet>
      <Navigation search_string={search_string} />
      {detailed_listing_state.web_unit && (
        <ShowListing listing_ts={detailed_listing_state} />
      )}
      <div className="explore-page search_wrapper">
        <div className="index-page-container">
          <div className="search-component-wrapper">
            <FilterButtons />
          </div>
          <SearchListPreview />
        </div>
      </div>
    </>
  );
};

export default IndexPage;
