import { csrfFetch } from "./csrf";
import { objectToQuerySting } from "./utils";
import {
  cleanLocalStorageSearchCredentials,
  getLocalStorageSearchCredentials,
} from "./utils";
import { createSelector } from "reselect";
import { fetchSearchResultsHelper } from "./searchReducer";

const relatedResultSelector = (state) => state.relatedResults;

const RECEIVE_RELATED_RESULTS = "api/search/RECEIVE_RELATED_RESULTS";
const RECEIVE_RELATED_BUILDINGS_RESULTS =
  "api/search/RECEIVE_RELATED_BUILDINGS_RESULTS";
const CLEAR_RELATED_RESULTS = "api/search/CLEAR_RELATED_RESULTS";

export const receiveRelatedResults = (relatedResults) => ({
  type: RECEIVE_RELATED_RESULTS,
  relatedResults,
});

export const receiveRelatedBuildingsResults = (relatedResults) => ({
  type: RECEIVE_RELATED_BUILDINGS_RESULTS,
  relatedResults,
});

const DEFAULT_OBJECT = {
  results: [],
};

const clearRelatedResults = () => ({
  type: CLEAR_RELATED_RESULTS,
});

export const getRelatedResults = createSelector(
  [relatedResultSelector],
  (relatedResults) => {
    if (relatedResults && relatedResults.results) {
      return relatedResults.results;
    }

    return [];
  }
);

export const getRelatedBuildingsResults = createSelector(
  [relatedResultSelector],
  (relatedResults) => {
    if (relatedResults && relatedResults.otherBuildings) {
      return relatedResults.otherBuildings;
    }

    return [];
  }
);

export const fetchRelatedResults =
  (unit, cap_results = 20) =>
  async (dispatch) => {
    const search_string = unit.building_name;

    const PRICE_TOL = 500;
    const SQR_FT_TOL = 0.8;
    const baseParams = {
      minPrice: String(unit.unit_price - PRICE_TOL),
      maxPrice: String(unit.unit_price + PRICE_TOL),
      squareFootFilter: Math.round(unit.unit_sqr_ft * SQR_FT_TOL),
      bedroom: unit.beds,
      bathroom: unit.baths,
      cap_results: cap_results,
    };
    console.log("related units", search_string, baseParams);
    fetchSearchResultsHelper(
      receiveRelatedResults,
      dispatch,
      search_string,
      baseParams
    );
  };

export const fetchBuildingResults =
  (unit, cap_results = 20) =>
  async (dispatch) => {
    const search_string = unit.building_name;
    const baseParams = {
      // ...getLocalStorageSearchCredentials(),
      cap_results: cap_results,
    };
    console.log("building units", search_string);
    fetchSearchResultsHelper(
      receiveRelatedResults,
      dispatch,
      search_string,
      baseParams
    );
  };

export const fetchRelatedBuildingsResults =
  (unit, cap_results = 20) =>
  async (dispatch) => {
    const search_string = "";

    const PRICE_TOL = 500;
    const SQR_FT_TOL = 0.9;
    const baseParams = {
      minPrice: String(unit.unit_price - 4 * PRICE_TOL),
      maxPrice: String(unit.unit_price + PRICE_TOL),
      squareFootFilter: Math.round(unit.unit_sqr_ft * SQR_FT_TOL),
      bedroom: unit.beds,
      bathroom: unit.baths,
      cap_results: cap_results,
      building_cap: 1,
      sort_by: "cheapest",
    };
    console.log("related building units", search_string, baseParams);
    fetchSearchResultsHelper(
      receiveRelatedBuildingsResults,
      dispatch,
      search_string,
      baseParams
    );
  };

export const clearAllRelatedResults = () => async (dispatch) => {
  dispatch(clearRelatedResults());
};

const relatedResultsReducer = (state = DEFAULT_OBJECT, action) => {
  const newState = { ...state };

  switch (action.type) {
    case RECEIVE_RELATED_RESULTS:
      if (action.relatedResults) {
        return { ...newState, results: action.relatedResults };
      }
      return state;
    case RECEIVE_RELATED_BUILDINGS_RESULTS:
      if (action.relatedResults) {
        console.log("related building results", action.relatedResults);
        return { ...newState, otherBuildings: action.relatedResults };
      }
      return state;
    case CLEAR_RELATED_RESULTS:
      return {};
    default:
      return state;
  }
};

export default relatedResultsReducer;
