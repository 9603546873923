import React from "react";

import { SQFTLOT, Gym, Dog } from "./assets/svgs";

import UnitHeader from "../UnitHeader/UnitHeader";
import { Link } from "react-router-dom";
import { formatAvailabilityDate } from "../UnitHeader/UnitHeader";

import "./style/home.scss";

const ModalUnitDetails = ({ listing, big_beds = true, is_available = true }) => {
  const unit = listing.unit;
  unit.address = unit.address.replace(",,", ",");

  const area_link = `/area/${unit.area.replace(" ", "_")}`;
  const availablity_text = is_available ? formatAvailabilityDate(unit.unit_availibility) : "not available";
  const show_details = !big_beds;
  return (
    <>
      <div className="top-container__header">
        <div className="right-header">
          <UnitHeader
            web_unit={listing}
            use_spacer={false}
            show_details={show_details}
          />
          <p className="show-address">{unit.address} {
            !show_details && <span className="show-availability">/ {availablity_text}</span>
          }</p>
        </div>

        {big_beds && (
          <div className="left-header">
            <p>
              <span>{unit.beds}</span> beds
            </p>
            <p>
              <span>{unit.baths}</span> baths
            </p>
            <p>
              <span> {unit.unit_sqr_ft}</span> sqft
            </p>
          </div>
        )}
      </div>

      <div className="listing-info">
        <div className="listing-info__header-menu">
          <ul className="details">
            <li>
              {<SQFTLOT />}{" "}
              {unit.area}
              {/* <Link to={area_link} className="area-link">
                {unit.area}
              </Link>{" "} */}
            </li>
            <li>
              {<Gym />} {unit.has_gym ? "Has gym" : "No gym"}
            </li>
            <li>
              {<Dog />}{" "}
              {unit.pet_friendly === undefined || unit.pet_friendly
                ? "Pet friendly"
                : "No pets"}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ModalUnitDetails;
