export const restoreSession = async () => {
  // let res = await csrfFetch("/api/session");
  // let token = res.headers.get("X-CSRF-Token");
  // if (token) {
  //   localStorage.setItem("X-CSRF-Token", token);
  // }
  // let data = await res.json();
  // if (data && data.user) {
  //   localStorage.setItem("currentUser", JSON.stringify(data.user));
  // }
};

export async function csrfFetch(url, options = {}) {
  const backend_url = `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_BACKEND_PORT}`;
  console.log(backend_url);
  console.log("Environment Backend Port:", process.env);

  url = backend_url + url;
  options.method = options.method || "GET";
  options.headers = options.headers || {};
  options.credentials = "include";
  options.headers["X-CSRF-Token"] = localStorage.getItem("X-CSRF-Token");

  if (options.method.toUpperCase() !== "GET") {
    if (
      !options.headers["Content-Type"] &&
      !(options.body instanceof FormData)
    ) {
      options.headers["Content-Type"] = "application/json";
    }
  }

  const res = await fetch(url, options);
  if (res.status >= 400) console.warn(res);
  return res;
}
