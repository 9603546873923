import React, { forwardRef } from "react";

import UnitHeader from "../UnitHeader/UnitHeader";
import SparkGraph from "../SparkGraph/SparkGraph";

import "./UnitListItem.scss";

const UnitListItem = forwardRef(({ listing_ts, index, onClick = () => { }, isSelected = false }, ref) => {
  const listing = listing_ts.web_unit;

  const layout_overrides = {
    width: 100,
    height: 25,
  };
  const spark_color =
    listing_ts.web_unit.price_change >= 0 ? "black" : "rgb(217, 0, 72)";
  const spark_dates = listing_ts.dates.slice(-14);
  const spark_prices = listing_ts.rent_ts.slice(-14);

  const row_class = `apartment-row ${isSelected ? "selected" : ""}`
  return (
    <div ref={ref} className={row_class} onClick={onClick}>
      <div className="unit-item-text-wrapper">
        <UnitHeader
          web_unit={listing}
          use_spacer={false}
          show_details={false}
        />
      </div>
      <div className="spark-graph-wrapper">
        <SparkGraph
          x={spark_dates}
          y={spark_prices}
          color={spark_color}
          layout_overrides={layout_overrides}
        />
      </div>
    </div>
  );
});

export default UnitListItem;
