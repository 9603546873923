import { createContext, useEffect } from "react";
import Modal from "./Modal";

import "./ModalContainer.scss";

export const CloseModalFunction = createContext();

const generateUID = () => {
  const timestamp = Date.now().toString(36);
  const randomPortion = Math.random().toString(36).substring(2, 15);
  return `${timestamp}-${randomPortion}`;
};

const ModalContainer = ({ modalAreaStyling, children, on_close }) => {
  useEffect(() => {
    document.querySelector("html").classList.toggle("scroll-lock");

    return () => {
      document.querySelector("html").classList.remove("scroll-lock");
    };
  });
  const customId = generateUID();

  const onClickOutside = (event) => {
    if (
      event.target.className === "modal-container" &&
      event.target.id === customId
    ) {
      on_close();
    }
  };

  return (
    <>
      <Modal
        id={customId}
        onClickOutside={onClickOutside}
        modalAreaStyling={modalAreaStyling}
      >
        {children}
      </Modal>
    </>
  );
};

export default ModalContainer;
