import Skeleton from "react-loading-skeleton";
import "./Featured.scss";

const FeaturedSkeleton = () => {
  return (
    <div className="slide-container">
      <a className="slide-container-link">
        <div className="slide-apartment-image">
          <Skeleton height={390} width={360} borderRadius="15px" />
        </div>
        <Skeleton height={"100%"} width={560} />
      </a>
    </div>
  );
};

export default FeaturedSkeleton;
