import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  setSquareFootFilter,
  getSquareFootFilter,
} from "../../../../store/searchFilters";
import CustomDropdown from "../CustomDropdown/CustomDropdown";

import "./SquareFootFilter.scss";

const SquareFootDropdown = () => {
  const dispatch = useDispatch();

  // Updated square foot options without the "Custom" option
  const squareFootOptions = [
    { label: "Min sq ft", value: 0 },
    { label: "500 sq ft", value: 500 },
    { label: "600 sq ft", value: 600 },
    { label: "750 sq ft", value: 750 },
    { label: "1000 sq ft", value: 1000 },
    { label: "1250 sq ft", value: 1250 },
    { label: "1500 sq ft", value: 1500 },
    { label: "2000 sq ft", value: 2000 },
  ];

  const handleDropdownChange = (e) => {
    const value = e.value;
    dispatch(setSquareFootFilter(parseInt(value, 10)));
  };

  return (
    <div className="home-listing-type-wrapper" id="dropdown-wrapper">
      {/* <select
        value={selectedOption}
        onChange={handleDropdownChange}
        className="square-foot-dropdown filter-btn" // Assign the class for styling
      >
        <option value="">Min Sqft</option>
        {squareFootOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select> */}

      <CustomDropdown
        options={squareFootOptions}
        onSelect={handleDropdownChange}
        getOption={getSquareFootFilter}
      />
    </div>
  );
};

export default SquareFootDropdown;
