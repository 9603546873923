import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import usersReducer from "./usersReducer";
import listingsReducer from "./listingsReducer";
import featuredReducer from "./featuredReducer";
import searchSuggestionsReducer from "./search";
import searchFiltersReducer from "./searchFilters";
import detailedListingReducer from "./detailedListingReducer";
import searchResultsReducer from "./searchReducer";
import relatedResultsReducer from "./relatedUnitsReducer";
import authReducer from "./authReducer";
import toursReducer from "./tourReducer";

const rootReducer = combineReducers({
  session: usersReducer,
  listings: listingsReducer,
  featured: featuredReducer,
  searchSuggestions: searchSuggestionsReducer,
  searchFilter: searchFiltersReducer,
  detailedListing: detailedListingReducer,
  searchResults: searchResultsReducer,
  relatedResults: relatedResultsReducer,
  auth: authReducer,
  tours: toursReducer,
});

let middleware = [thunk];

if (process.env.NODE_ENV !== "production") {
  const { createLogger } = require("redux-logger");
  middleware = [...middleware, createLogger()];
}

const configureStore = (preloadedState = {}) =>
  createStore(rootReducer, preloadedState, applyMiddleware(...middleware));

export default configureStore;
