import React from 'react';
import { Helmet } from "react-helmet";

import './NewRenterPage.scss';
import Navigation from "../Header/Navigation";
import Footer from "../Footer";

import DEFAULT_IMAGE1 from '../assets/cat_graphics/cat_boat.webp';
import DEFAULT_IMAGE2 from '../assets/cat_graphics/chicago_theatre_cat.webp';

import high_rise_building from '../assets/apartment_types/highrise.jpg';
import high_rise_inside from '../assets/apartment_types/1000M_interior.jpg';

import loft_building from '../assets/apartment_types/industrial-loft-apartment-exterior.jpg';
import loft_inside from '../assets/apartment_types/loft_interior2.webp';

import river_north from '../assets/cat_graphics/cat_boat.webp';
import west_loop from '../assets/cat_graphics/cat_west_loop.webp';
import south_loop from '../assets/cat_graphics/cat_dearborn.webp';
import loop from '../assets/cat_graphics/cat_river_bean.webp';

import cat_swing from '../assets/cat_graphics/cat_swing.webp';
import cat_tour from '../assets/cat_graphics/cat_tour.webp';

export const TestRow = ({ title = '', text = '', text_provider = () => { }, tight_top = false }) => {
    const top_padding_style = tight_top ? { marginTop: 30 } : {};
    return (
        <div className='row'>
            <div class="text-wrapper" style={top_padding_style}>
                {title ? <h1> {title} </h1> : <></>}
                <h2>{text}{text_provider()}</h2>
            </div>
        </div>
    )
};

export const Spacer = () => <div class="spacer"></div>;

export const TwoImageRow = ({ img1 = DEFAULT_IMAGE1, img2 = DEFAULT_IMAGE2, label1 = "", label2 = "", img1_position = 'center', img2_position = 'center', style = { height: 400 } }) => {
    return (
        <div className="centered-content">
            <div className="image-container" style={style}>
                <div className='image-wrapper'>
                    <img src={img1} alt={label1} style={{ objectPosition: img1_position }} />
                    <span class='img-label'>{label1}</span>
                </div>
            </div>
            <div className="image-container" style={style}>
                <div className='image-wrapper'>
                    <img src={img2} alt={label2} style={{ objectPosition: img2_position }} />
                    <span class='img-label'>{label2}</span>
                </div>

            </div>
        </div>
    );
};

export const ImageTextRow = ({ img = DEFAULT_IMAGE1, label = "", title = "Title text", body = "", style = {}, text_right = true, text_provider = () => { } }) => {
    const text_block = (
        <div className="text-container">
            <h1>{title}</h1>
            <p>{body}{text_provider()}</p>
        </div>
    );
    return (
        <div className="centered-content">
            {!text_right && text_block}
            <div className="image-container">
                <div className='image-wrapper' style={style}>
                    <img src={img} alt={label} />
                    <span class='img-label'>{label}</span>
                </div>
            </div>
            {text_right && text_block}
        </div>
    );
};

export const ImageTextBlock = ({ img = DEFAULT_IMAGE1, label = "", title = "", body = "" }) => {
    return (
        <>
            <div className="centered-content">
                <div className="image-container full_row">
                    <div className='image-wrapper'>
                        <img src={img} alt={label} />
                        <span class='img-label'>{label}</span>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div class="text-wrapper img_subtext">
                    {title ? <h1> {title} </h1> : <></>}
                    <p><i>{body}</i></p>
                </div>
            </div>
        </>
    );
};

const NewRenterContent = () => {
    return (
        <div className='blog_container'>
            <div className="header">
                <h1 className='title'>Chicago for New Renters</h1>
            </div>

            <div className="content">
                <TestRow text_provider={() => <>
                    Welcome to Chicago! <br /> Lets overview renting basics and your options in the windy city.
                </>} />

                <TestRow title='Apartment Types' text="The main types of apartments are highrise, loft, walkup, and condo." />
                <Spacer />

                <TwoImageRow img1={high_rise_building} img1_position='right' label1='HIGH RISE' img2={high_rise_inside} />
                <TwoImageRow img1={loft_inside} img2={loft_building} label2='LOFT' img1_position='right' />

                <TestRow text_provider={() => <>
                    Highrises are the fancy apartment buildings. <br />
                    Highrises often have a gym, pool and hot tub. They also have the best views and locations in the city.
                    Rent-Arb focuses on highrises.
                </>} />

                <TestRow tight_top text="Lofts are converted industrial buildings. They have high ceilings but no ameneties." />
                <TestRow tight_top text="Walkups are residential houses. These are the cheapest options but tend to be far from the city." />
                <TestRow tight_top text="Condos are like highrises but owned by an individual instead of a business. Quality and maintenance are not consistent but can be cheaper." />
                <Spacer />

                <TestRow title='Chicago Neighborhoods' text='Chicago districts are named off of their relative location to the "Loop" (the business district).' />
                <Spacer />

                <ImageTextRow img={river_north} title='River North' body='Young urban professionals like to live here. There are a bunch of clubs and restaurants here while being close to the Loop for work.' />
                <ImageTextRow img={west_loop} title='West Loop' body='The best sit-down restaurants are in Fulton Market in West Loop.' text_right={false} />
                <ImageTextRow img={south_loop} title='South Loop' body='You can get a bigger apartment with a great view here for cheap while being close to work. There is little nightlife but there is a Trader Joes!' />
                <ImageTextRow img={loop} title='The Loop' body='Try not to live in the Loop. There are no grocery stores and most restaurants only do lunch.' text_right={false} />

                <TestRow text='Other options include Old Town, Lincoln Park, Wicker Park, Lakeview, Gold Coast, Hyde Park and Boys Town!' />
                <Spacer />

                <TestRow title='Tips and Tricks' text_provider={() => <>
                    Prices can change <i><b>a lot </b> </i> week to week!</>} />

                <ImageTextBlock img={cat_swing} body="For some buildings these can be $500 swings, taking units in and out of your budget." />

                <TestRow tight_top text="Rent-Arb lets you find which units are value buys, as well as email you when apartments you follow change in price." />

                <Spacer />
                <ImageTextRow img={cat_tour} title='Special Offers' body='One month off promotions substantially impact your effective monthly rent price.
                You usually recieve a discount after you tour or email, which is only valid for a limited time.' />

                <TestRow text="
                You’ll want to only officially tour buildings the day you’re ready to sign and you’ll want to tour all buildings on the same day."/>

                <TestRow text='Leasing agents are paid by the buildings in Chicago!
                You can contact one to help drive you around and show you buildings for free.
                They are generally paid 1 month’s rent from the building you sign with.' />

                <Spacer />
            </div>
        </div>
    );
};

const NewRenterPage = () => {
    return (
        <>
            <Helmet>
                <title>Chicago Renting for Beginners | Rent-Arb</title>
            </Helmet>
            <Navigation />
            <NewRenterContent />
            <Footer />
        </>
    );
}

export default NewRenterPage;
