import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getSuggestions } from "../../store/search";
import { fetchSearchResults } from "../../store/searchReducer";
import { recieveSearch } from "../../store/searchFilters";
import { clearDetailedListing } from "../../store/detailedListingReducer";

import SplashSearchInput from "./SplashSearchInput";

import "./SearchBar.scss";

const SearchBar = ({ search_string = "" }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const suggestions = useSelector(getSuggestions);
  const [suggestionsBox, setSuggestionsBox] = useState(false);
  const [term, setTerm] = useState("");
  const [value, setValue] = useState(search_string);

  const handleSearchOnChange = (e) => {
    const searchString = e.target.value;
    setValue(searchString);
  };

  const handleSearchSubmit = (e) => {
    const search_string = value;
    e.preventDefault();

    dispatch(fetchSearchResults(search_string));
    dispatch(clearDetailedListing());
    dispatch(recieveSearch());

    navigate("/listings", { state: { search_string: search_string } });
  };

  return (
    <SplashSearchInput
      handleSearchSubmit={handleSearchSubmit}
      handleSearchOnChange={handleSearchOnChange}
      value={value}
      term={term}
      setSuggestionsBox={setSuggestionsBox}
      suggestionsBox={suggestionsBox}
      suggestions={suggestions}
    />
  );
};

export default SearchBar;
