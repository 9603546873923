import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ListingIndexItemHeart from "./ListingHeart";
import ShowListing from "../ShowListing/index";

// import { addFavorite, removeFavorite } from "../../store/listingsReducer";
import { getActiveUser } from "../../store/usersReducer";
import { receiveDetailedListing } from "../../store/detailedListingReducer";
import { favouriteUnit } from "../../store/usersReducer";

import "./ListingItem.scss";

const ListingItem = ({
  listing_ts,
  listingStyling,
  thumbnailStyling,
  index,
  detailed = false,
  show_favorite = false,
  onClick,
}) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getActiveUser());

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
  const listing = listing_ts.web_unit;
  listing.favorite = false;
  const unit = listing.unit;
  unit.address = unit.address ? unit.address.replace(",,", ",") : unit.address;

  const price = formatter.format(unit.unit_price);

  //   const handleFavoriteClick = (e, listingId) => {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     dispatch(addFavorite(currentUser.id, listingId));
  //   };

  const showListing = () => {
    dispatch(receiveDetailedListing(listing_ts));
  };

  const onClickAggregate = () => {
    if (onClick) {
      onClick(listing_ts);
    } else {
      showListing();
    }
  };

  const onFav = (e) => {
    e.stopPropagation();
    dispatch(favouriteUnit(listing_ts.web_unit.unit.id));
  };

  const tick_symbol = listing.price_change > 0 ? "↑" : "↓";
  const tick_color = listing.price_change >= 0 ? "black" : "rgb(217, 0, 72)";
  const tick_price = Math.abs(listing.price_change);
  const photo_index = (index + 1) % listing.photo_urls.length;
  const photo_url = listing.photo_urls[photo_index];
  const encodedPhotoURL = photo_url.replace(/\(/g, "%28").replace(/\)/g, "%29");
  const is_available = listing_ts.rent_ts.slice(-1)[0];
  const isFav = is_available;
  return (
    <>
      <li
        className="listing_item"
        style={listingStyling}
        onClick={onClickAggregate}
      >
        <div className="listing_item__content_box">
          <div
            className="listing_item__thumbnail"
            style={{
              ...thumbnailStyling,
              backgroundImage: `url(${encodedPhotoURL})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            {show_favorite && (
              <div
                className="listing_item__thumbnail__favorite"
                onClick={onFav}
              >
                <ListingIndexItemHeart isFavorite={isFav} />
              </div>
            )}
          </div>

          <div className="listing_item__info">
            <h1>{price}</h1>
            <div className="listing_item__info__details">
              <p>
                <span className="listing_item__info__details__bold_span">
                  {unit.beds}
                </span>{" "}
                bd{" "}
                <span className="listing_item__info__details__light_span">
                  |
                </span>
              </p>
              <p>
                <span className="listing_item__info__details__bold_span">
                  {unit.baths}
                </span>{" "}
                ba{" "}
                <span className="listing_item__info__details__light_span">
                  |
                </span>
              </p>
              <p>
                <span className="listing_item__info__details__bold_span">
                  {unit.unit_sqr_ft}
                </span>{" "}
                sqft{" "}
                <span className="listing_item__info__details__light_span">
                  |
                </span>
              </p>
              <p>#{unit.unit_number.toString().substring(0, 14)}</p>
            </div>
            <p className="listing_item__info__details__pchange">
              <span style={{ color: tick_color }}>
                {" "}
                {tick_symbol} ${tick_price}{" "}
              </span>{" "}
              this week
            </p>
            <p className="listing_item__info__details__building">
              {unit.building_name}
            </p>
            {/* <p className="listing_item__info__details__address">
							{`${unit.address}`}
						</p> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {detailed && (
                <p className="listing_item__info__details__listing_by">
                  {" "}
                  LISTING BY: {"rq2jiang".toUpperCase()}
                </p>
              )}
            </div>
          </div>
        </div>
        {/* </Link> */}
      </li>
    </>
  );
};

export default ListingItem;
