import { useState, useEffect } from "react";

import { BackArrow, Heart, Share } from "./assets/svgs";

import "./style/ListingHeader.scss";

const ListingHeader = ({
  onShare = () => {},
  on_close = () => {},
  onFav = () => {},
  isFav = false,
}) => {
  const [fillFav, setFav] = useState(isFav);
  useEffect(() => {
    setFav(isFav);
  }, [isFav]);
  const onFavClick = () => {
    setFav(!fillFav);
    onFav();
  };
  const back_text = window.innerWidth < 600 ? "Back" : "Back to search";
  return (
    <header className="listing_header">
      <div className="back-to-listing" onClick={() => on_close()}>
        <BackArrow /> <span>{back_text}</span>
      </div>
      <div className="grid-item middle"></div>
      <ul>
        <li onClick={onFavClick}>
          <Heart filled={fillFav} /> Follow{" "}
        </li>
        <li onClick={onShare}>
          <Share /> Share
        </li>
      </ul>
    </header>
  );
};

export default ListingHeader;
