import React from "react";
import { useDispatch } from "react-redux";
import UnitHeader from "../UnitHeader/UnitHeader";
import ModalUnitDetails from "../ShowListing/ModalUnitDetails";

import { receiveDetailedListing } from "../../store/detailedListingReducer";

import "./UnitDetails.scss";

const UnitDetails = ({ listing_ts }) => {
  const dispatch = useDispatch();
  const showListing = () => {
    dispatch(receiveDetailedListing(listing_ts));
  };

  return (
    <>
      {listing_ts && (
        <>
          <div className="search-unit-details-wrapper">
            <a className="slide-container-link">
              <div className="slide-apartment-image" onClick={showListing}>
                <img
                  src={listing_ts.web_unit.photo_urls[0]}
                  className="search-featured-img"
                />
              </div>
            </a>
            {/* <h1> {listing_ts.web_unit.unit.building_name} </h1> */}
            {/* <UnitHeader web_unit={listing_ts.web_unit} /> */}
            <ModalUnitDetails listing={listing_ts.web_unit} big_beds={false} />
          </div>
        </>
      )}
    </>
  );
};

export default UnitDetails;
