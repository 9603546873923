import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import ModalContainer from "../Modal/ModalContainer";
import AuthorizedUser from "./AuthorizedUser";
import SearchBar from "../SearchBar/SearchBar";
import LoginContainer from "../Login/LoginContainer";

import { clearDetailedListing } from "../../store/detailedListingReducer";
import { getActiveUser } from "../../store/usersReducer";
import { abortLoginAction } from "../../store/authReducer";

import "./Navigation.scss";

const Navigation = ({ in_modal, search_string = "" }) => {
  const dispatch = useDispatch();
  const activeUser = useSelector(getActiveUser());
  const { email, password } = useSelector((state) => state.auth);
  const closeModal = () => {
    dispatch(clearDetailedListing());
  };
  const [showModal, setModal] = useState(false);

  const modalAreaStyling = {
    display: "flex",
    flexDirection: "column",
    padding: "27px 16px 14px 16px",
    borderRadius: "10px",
    width: "456px",
    backgroundColor: "rgb(255 255 255)",
  };

  const close_login = () => {
    setModal(false);
    if (email && !password) {
      dispatch(abortLoginAction());
    }
  };

  const SignIn = () => (
    <>
      <button className="session-button" onClick={() => setModal(true)}>
        Sign in
      </button>

      {showModal && (
        <ModalContainer
          modalAreaStyling={modalAreaStyling}
          on_close={close_login}
        >
          <LoginContainer />
        </ModalContainer>
      )}
    </>
  );

  let on_click = in_modal ? closeModal : () => {};
  return (
    <>
      <div className="container">
        <nav id="navigation">
          <div className="grid-item left">
            <Link to="/" className="logo-link" onClick={on_click}>
              <h1 className="logo">RENT-ARB</h1>
            </Link>

            <a
              class="logo-link sublink-wrapper"
              href="/new_renters"
              target="_blank"
            >
              <h2 class="sublink">New Renters</h2>
            </a>

            <SearchBar search_string={search_string} />
          </div>
          <div className="grid-item right">
            {activeUser ? <AuthorizedUser /> : <SignIn />}
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navigation;
