import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GoogleLoginButton,
  FacebookLoginButton,
} from "react-social-login-buttons";

import { checkEmail } from "../../store/authReducer";

import "./Login.scss";

function Login({ closeModal }) {
  const [email, setEmail] = useState("");
  const { emailExists, isChecking } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleSocialLogin = (socialNetwork) => {
    const backend_url = `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_BACKEND_PORT}`;
    window.location.href = `${backend_url}/auth/${socialNetwork}`;
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    dispatch(checkEmail(email));
  };

  return (
    <div className="login-part-one">
      <form onSubmit={handleEmailSubmit} className="login_form">
        <div className="login-input-wrapper">
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
          />
        </div>
        <button type="submit" disabled={isChecking} className="sign-in-btn">
          Submit
        </button>
      </form>

      <div class="login-divider">OR</div>
      <div className="login-socials">
        <GoogleLoginButton
          onClick={() => handleSocialLogin("google")}
          className="top-button"
        />
        <div className="spacer"></div>
        <FacebookLoginButton onClick={() => handleSocialLogin("facebook")} />
      </div>
    </div>
  );
}

export default Login;
