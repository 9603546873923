import React, { useRef } from "react";
import { Helmet } from "react-helmet";


import ImageWithTimeout from "../ImageWithTimeout/ImageWithTimeout";

import "./style/gallery.scss";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Ensure Swiper styles are imported
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import SwiperCore, { Navigation, Pagination } from 'swiper';
SwiperCore.use([Navigation, Pagination]);

const TiledGallery = ({ gallery_layout, photos }) => {
  return (
    <div className={gallery_layout}>
      <Helmet>
        <meta property="og:image" content={photos[0]}></meta>
        <meta property="og:image:width" content="200"></meta>
        <meta property="og:image:height" content="200"></meta>
      </Helmet>
      {photos.map((photo, idx) => {
        return (
          <div className='gallery-item' style={{ 'grid-area': `img_${idx}` }} key={idx}>
            <ImageWithTimeout alt={`Home ${idx + 1}`} src={photo} />
          </div>
        );
      })}
    </div>
  );
};

const SwiperGallery = ({ photos }) => {
  const swiperRef = useRef(null);

  return (
    <div className="swipe-gallery">
      <Swiper
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        slidesPerView={1}
        pagination={{ clickable: true }}
        loop={true}
      >
        {photos.map((photo, idx) => {
          return (
            <SwiperSlide key={idx}>
              <div className='gallery-item'>
                <ImageWithTimeout alt={`Home ${idx + 1}`} src={photo} />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

const Gallery = ({ listing }) => {
  const isMobile = window.innerWidth < 600;
  const photos = listing.photo_urls.slice(0, 5);
  if (isMobile) {
    return (<SwiperGallery photos={photos} />);
  } else {
    const gallery_layout = `gallery gallery_${photos.length}`;
    return (
      <TiledGallery gallery_layout={gallery_layout} photos={photos} />
    );
  }

};

export default Gallery;
