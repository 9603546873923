import Navigation from "../Header/Navigation";
import Footer from "../Footer";

import './ContactPage.scss';

const ContactContent = () => {
    return (
        <div className="contact-content contact-margins">
            <div className="contact-root">
                <div style={{ height: '24px', width: '24px', flexShrink: '0' }}></div>
                <span style={{ color: 'inherit', fontSize: '28px', lineHeight: '36px', fontWeight: '500' }}>Contact Us</span>
                <div className="content-0-1-692">
                    <div className="text-0-1-693">
                        <div style={{ height: '24px', width: '24px', flexShrink: '0' }}></div>
                        <span style={{ color: 'inherit', fontSize: '16px', lineHeight: '24px', fontWeight: '400' }}>
                            We are eager to hear your feedback and help with whatever we can. If none of the support channels on the right work for you, please email support@rent-arb.com.
                        </span>
                    </div>
                    <div>
                        <div style={{ fontFamily: 'Arial, sans-serif', borderRadius: '8px', border: '1px solid #ccc', padding: '8px 16px', width: '400px' }}>
                            <div style={{ fontFamily: 'Arial, sans-serif' }}>
                                <a href="mailto:support@rent-arb.com" className="row-0-1-694  interactive-0-1-695" target="_blank" rel="noopener noreferrer">
                                    <svg className="css-10dohqv" focusable="false" color="inherit" aria-hidden="true" viewBox="0 0 24 24" style={{ fontSize: '24px', verticalAlign: 'middle' }}>
                                        <path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"></path>
                                    </svg>
                                    <div style={{ marginLeft: '12px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                                        <div className="line-0-1-697" style={{ color: 'inherit' }}>
                                            <span><span style={{ color: 'inherit', fontSize: '14px', lineHeight: '20px', fontWeight: '400' }}>Need to reach out?</span></span>
                                            <span style={{ flexShrink: '0', color: 'inherit', fontSize: '16px', lineHeight: '24px', fontWeight: '600' }}>support@rent-arb.com</span>
                                        </div>
                                    </div>
                                </a>
                                <hr className="line-0-1-697" style={{ backgroundColor: '#ccc', width: 'calc(100% + 0px)', height: '1px', marginInline: '0px', marginBlock: 'auto' }} />
                                <a href="" className="row-0-1-694  interactive-0-1-695">
                                    <svg className="css-10dohqv" focusable="false" color="inherit" aria-hidden="true" viewBox="0 0 24 24" style={{ fontSize: '24px', verticalAlign: 'middle' }}>
                                        <path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"></path>
                                    </svg>
                                    <div style={{ marginLeft: '12px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                                        <div className="line-0-1-697" style={{ color: 'inherit' }}>
                                            <span style={{ color: 'inherit', fontSize: '14px', lineHeight: '20px', fontWeight: '400' }}>Social</span>
                                            <div className="icon-row">
                                                <div className="contents cropped item-block">
                                                    <a href="https://www.linkedin.com/" className="url-link item-content-box item-block" target="_blank" rel="noopener noreferrer">
                                                        <img className="item-content-box item-block image" alt="" src="//v.fastcdn.co/t/62ba1c13/17bf3b84/1679441101-57903611-26x24-LinkedIn-Logo.png" srcSet="//v.fastcdn.co/t/62ba1c13/17bf3b84/1679441102-57903611-52x48-LinkedIn-Logo.png 2x" />
                                                    </a>
                                                </div>
                                                <div className="contents cropped item-block">
                                                    <a href="https://www.instagram.com/" className="url-link item-content-box item-block" target="_blank" rel="noopener noreferrer">
                                                        <img className="item-content-box item-block image" alt="" src="//v.fastcdn.co/t/62ba1c13/17bf3b84/1679441100-57903601-26x24-Instagram-Logo.png" srcSet="//v.fastcdn.co/t/62ba1c13/17bf3b84/1679441101-57903601-52x48-Instagram-Logo.png 2x" />
                                                    </a>
                                                </div>
                                                <div className="contents cropped item-block">
                                                    <a href="https://www.twitter.com/" className="url-link item-content-box item-block" target="_blank" rel="noopener noreferrer">
                                                        <img className="item-content-box item-block image " data-at="image" alt="" src="//v.fastcdn.co/t/62ba1c13/17bf3b84/1679441099-57903581-26x20-Twitter-Logo.png" srcset="//v.fastcdn.co/t/62ba1c13/17bf3b84/1679441099-57903581-52x40-Twitter-Logo.png 2x" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

const ContactPage = () => {
    return (
        <>
            <Navigation />
            <ContactContent />
            <Footer />
        </>
    );
}

export default ContactPage;
