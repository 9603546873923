import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { tryLogin, createAccount } from "../../store/authReducer";

import "./Password.scss";
import "./Login.scss";

export const LoginWithPassword = () => {
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const { email, isChecking } = useSelector((state) => state.auth);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(tryLogin(email, password));
  };

  return (
    <div className="password-component">
      <form onSubmit={handleSubmit} className="login_form">
        <div className="login-input-wrapper">
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
        </div>
        <button type="submit" disabled={isChecking}>Log In</button>
      </form>
      <button className="forgot-password">Forgot Password?</button>
    </div>
  );
};

export const NewPassword = () => {
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const { email, isChecking } = useSelector((state) => state.auth);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createAccount(email, password));
  };

  return (
    <div className="password-component">
      <form onSubmit={handleSubmit} className="login_form">
        <div className="login-input-wrapper">
          <label>Create a password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            required
          />
        </div>
        <button type="submit" disabled={isChecking}>Sign Up</button>
      </form>
    </div>
  );
};
