import React from "react";
import { getExcludes, setExcludes } from "../../../../store/searchFilters";
import CustomCheckboxes from "../CustomCheckboxes/CustomCheckboxes";

const HomeType = () => {
  const HOME_TYPES = [
    "Gym",
    "Pet friendly",
    "Pool",
    "Hot tub",
  ];

  return (
    <CustomCheckboxes
      OPTIONS={HOME_TYPES}
      getter={getExcludes}
      setter={setExcludes}
      label="Amenities"
    />
  );
};

export default HomeType;
