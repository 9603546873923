import React from "react";
import Plot from "react-plotly.js";

const SparklineGraph = ({ x, y, color = "black", layout_overrides = {} }) => {
  const trace = {
    type: "scatter", // Use a scatter plot for the line
    mode: "lines", // Use lines mode
    x: x, // Assuming `data` is an array of { date, price }
    y: y,
    line: { color: color, shape: 'spline' }, // Example line color
    width: 0.1,
    connectgaps: true,
  };

  const layout_defaults = {
    margin: { l: 0, r: 0, t: 0, b: 0 }, // Remove margins
    xaxis: { visible: false }, // Hide x-axis
    yaxis: { visible: false }, // Hide y-axis
    showlegend: false, // Do not show legend
    paper_bgcolor: 'rgba(0,0,0,0)',  // Makes the plot background transparent
    plot_bgcolor: 'rgba(0,0,0,0)',
  };

  const layout = { ...layout_defaults, ...layout_overrides };

  const config = {
    staticPlot: true, // Make the plot static (no interactions)
    displayModeBar: false, // Hide the mode bar (no toolbar)
  };

  return <Plot data={[trace]} layout={layout} config={config} />;
};

export default SparklineGraph;
