import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";

import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./CustomDropdown.scss";

const CustomDropdown = ({ options, onSelect, getOption, style }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedOption = useSelector(getOption);
  const dropdownRef = useRef(null);
  // const [selectedOption, setSelectedOption] = useState(initial_option || options[0]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleOptionClick = (option) => {
    // setSelectedOption(option);
    onSelect(option); // Propagate selection
    setIsOpen(false); // Close dropdown
  };

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <div className="dropdown-button" onClick={toggleDropdown}>
        {options.filter((element) => element.value == selectedOption)[0].label}
        <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} />
      </div>
      {isOpen && (
        <ul className="dropdown-options">
          {options.map((option) => (
            <li key={option.value} onClick={() => handleOptionClick(option)}>
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
