import { csrfFetch } from "./csrf";

// ACTION TYPES
const RECEIVE_USER = "users/RECEIVE_USER";
const REMOVE_USER = "users/REMOVE_USER";
const UPDATE_USER = "users/UPDATE_USER";
const FAV_UNIT_REQUEST = "users/FAV_UNIT_REQUEST";
const FAV_UNIT_SUCCESS = "users/FAV_UNIT_SUCCESS";
// ACTION CREATORS
export const receiveUser = (user) => ({
  type: RECEIVE_USER,
  payload: user,
});

export const removeUser = () => ({
  type: REMOVE_USER,
});

export const getActiveUser = () => (state) => {
  if (state && state.session.user) {
    return state.session.user;
  }

  return null;
};

export const updateUserInfoAction = (user) => ({
  type: UPDATE_USER,
  payload: user,
});

export const updateUserInfo = (userData) => async (dispatch) => {
  // dispatch(updateUserInfoAction(userData));
  try {
    console.log("user update", userData);
    let res = await csrfFetch("/users", {
      method: "PATCH",
      body: JSON.stringify(userData),
    });
    if (res.ok) {
      const user = await res.json();
      console.log("updated user", user);
      dispatch(receiveUser(user));
    } else {
      try {
        const { errors } = await res.json();
        throw new Error(errors.join(", "));
      } catch (jsonError) {
        throw new Error(res.statusText);
      }
    }
  } catch (error) {
    throw error;
  }
};

export const logoutUser = () => async (dispatch) => {
  await csrfFetch("/users/logout", {
    method: "POST",
  });
  // localStorage.removeItem("currentUser");
  dispatch(removeUser());
};

export const createUser = (user) => async (dispatch) => {
  // let res = await csrfFetch("/api/users", {
  //   method: "POST",
  //   body: JSON.stringify(user),
  // });
  // if (res.ok) {
  //   let data = await res.json();
  //   localStorage.setItem("currentUser", JSON.stringify(data.user));
  //   dispatch(receiveUser(data.user));
  // } else {
  //   const { errors } = await res.json();
  //   throw new Error(errors);
  // }
};

export const fetchCurrentUser = () => async (dispatch) => {
  const res = await csrfFetch("/users/me");
  if (res.ok) {
    const user = await res.json();
    dispatch(receiveUser(user));
  }
};

export const favouriteUnit = (unit_id) => async (dispatch) => {
  dispatch({ type: FAV_UNIT_REQUEST, payload: unit_id });
  try {
    const res = await csrfFetch(`/users/favourite/${unit_id}`, {
      method: "POST",
    });
    if (res.ok) {
      const result = await res.json();
      dispatch({ type: FAV_UNIT_SUCCESS, payload: result });
    }
  } catch (error) {
    console.error("Failed to favourite unit: ", error);
  }
};

// REDUCER
const userReducer = (state = {}, action) => {
  const nextState = { ...state };

  switch (action.type) {
    case RECEIVE_USER:
      const user = action.payload;
      user.fav_units = user?.fav_units?.split(" ") || [];
      nextState.user = user;
      return nextState;
    case REMOVE_USER:
      nextState["user"] = null;
      return nextState;
    case UPDATE_USER:
      nextState.user = { ...nextState.user, ...action.payload };
      return nextState;
    case FAV_UNIT_SUCCESS:
      nextState.user.fav_units = action.payload?.split(" ") || [];
      return nextState;
    default:
      return state;
  }
};

export default userReducer;
