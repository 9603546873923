import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	ChakraProvider,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
} from "@chakra-ui/react";

import { getActiveUser } from "../../store/usersReducer";
import { useLocation, useNavigate } from 'react-router-dom';

import tabListTheme from "../Modal/ModalTabsTheme";
import UserProfile from "./UserProfile";
import Favorites from "./Favorites";

const UserProfileTabs = () => {
	const dispatch = useDispatch();
	const currentUser = useSelector(getActiveUser());
	const current_favs = useSelector((state) => state?.session.user?.fav_units);
	const [tabIndex, setTabIndex] = useState(0);
	const location = useLocation();
	const navigate = useNavigate();

	const getTabIndexFromLocation = () => {
		// Example with hash; adjust if using query params
		const hash = location.hash;
		switch (hash) {
			case '#profile':
				return 1;
			case '#favorites':
				return 0;
			default:
				return 0; // Default tab
		}
	};

	useEffect(() => {
		setTabIndex(getTabIndexFromLocation());
	}, [location]);

	// Update the URL when tabs change
	const handleTabsChange = (index) => {
		switch (index) {
			case 1:
				navigate('#profile');
				break;
			case 0:
				navigate('#favorites');
				break;
			default:
				break;
		}
	};

	return (
		<div className="profile-wrapper">
			<ChakraProvider theme={tabListTheme}>
				<Tabs isLazy index={tabIndex} onChange={handleTabsChange}>
					<div className="tabs-wrapper">
						<TabList borderBottom={"none"}>
							<Tab className="form-tab">Favorite Homes</Tab>
							<Tab>Profile</Tab>
						</TabList>
					</div>
					<div className="content">
						<TabPanels>
							<TabPanel>{currentUser ? <Favorites fav_units={current_favs} /> : "loading"}</TabPanel>
							<TabPanel><UserProfile currentUser={currentUser} /></TabPanel>
						</TabPanels>
					</div>
				</Tabs>
			</ChakraProvider>
		</div>
	);
};

export default UserProfileTabs;
