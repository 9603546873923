import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Login from "./Login";
import { LoginWithPassword, NewPassword } from "./Password";

import "./LoginContainer.scss";

function get_login_stage(emailExists) {
    switch (emailExists) {
        case null:
            return <Login />;
        case true:
            return <LoginWithPassword />;
        case false:
            return <NewPassword />;
        default:
            return <>error processing email login</>;
    }
}

function LoginContainer() {
    const { emailExists, isChecking } = useSelector((state) => state.auth);

    return (
        <div className="login-part-two">
            <h2 className="login-header">Welcome to Chicago!</h2>
            {get_login_stage(emailExists)}
        </div>
    );
}

export default LoginContainer;
