import React, { useState } from 'react';

import "../UserProfile/UserProfile.scss";
import "./InputField.scss";

const InputField = ({ defaultValue, onChange, name, label, type = null }) => {
    type = type || name;
    label = label || name;
    return (
        <div width="1" className="field-wrapper">
            <div className="labeled-input">
                <label htmlFor={name} className="mini-label"> {label} </label>
                <input id={name} type={name} name={name} className="text-input" defaultValue={defaultValue} onChange={onChange} />
            </div>
        </div>
    );
};

export default InputField;
