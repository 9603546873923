import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { getActiveUser, logoutUser } from "../../store/usersReducer";

import "./AuthorizedUser.scss";

const AuthorizedUser = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getActiveUser());

  const [dropDown, setDropDown] = useState(false);

  useEffect(() => {
    function handleOutsideClick(e) {
      if (!e.target.closest(".authorized-user-container")) {
        setDropDown(false);
      }
    }

    if (dropDown) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [dropDown]);

  const handleClick = (e) => {
    e.preventDefault();

    setDropDown(!dropDown);
  };

  const handleSignOutClick = (e) => {
    e.preventDefault();

    dispatch(logoutUser());
  };

  const profile_pigs = ["cat_grey.png", "cat_orange.png", "cat_panda.png"];
  const profile_pic = profile_pigs[currentUser.id % profile_pigs.length];
  const user_url_prefix = `/user/${currentUser.id}`;
  return (
    currentUser && (
      <div className="authorized-user-container">
        <img
          className="authorized-user"
          src={`/${profile_pic}`}
          alt="profile-pic"
          onClick={handleClick}
        />
        {dropDown && (
          <ul className="authorized-user__dropdown">
            <NavLink
              to={`${user_url_prefix}#favourites`}
              className="auth-menunav-link"
            >
              <li>Your homes</li>
            </NavLink>

            <NavLink
              to={`${user_url_prefix}#profile`}
              className="auth-menunav-link"
            >
              <li>Profile</li>
            </NavLink>
            {/* <li>Strategy overview</li> */}
            <hr />
            <li onClick={handleSignOutClick}>Sign Out</li>
          </ul>
        )}
      </div>
    )
  );
};

export default AuthorizedUser;
