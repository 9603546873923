import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import Navigation from "../Header/Navigation";
import ListingsCarousel from "../ListingsCarousel/ListingsCarousel";
import Featured from "../Featured/Featured";
import Footer from "../Footer";
import ShowListing from "../ShowListing/index";
import { generateRandomString } from "../utils/utils";

import { Spacer, ImageTextRow } from "../NewRenter/NewRenterPage";
import cat_tourist from "../assets/cat_graphics/cat_chicago_bean.webp";

import { fetchListings, clearAllListings } from "../../store/listingsReducer";
import { fetchFeatured } from "../../store/featuredReducer";
import {
  getDetailedListing,
  fetchDetailedListing,
  fetchBuilding,
} from "../../store/detailedListingReducer";

import "react-loading-skeleton/dist/skeleton.css";
import "./SplashPage.scss";

const is_building = (input) => {
  return input.split("-").length - 1 === 0 || input.endsWith("-0-");
};

const get_building_name = (input) => {
  return input.split("-")[0].replaceAll("_", " ");
};

const SplashPage = () => {
  const dispatch = useDispatch();
  const detailed_listing_state = useSelector(getDetailedListing);
  let { listing_id } = useParams();

  useEffect(() => {
    dispatch(fetchListings());
    dispatch(fetchFeatured());
    console.log("path_param", listing_id);
    if (listing_id) {
      if (is_building(listing_id)) {
        console.log("fetching building");
        dispatch(fetchBuilding(listing_id));
      } else {
        console.log("fetching unit");
        dispatch(fetchDetailedListing(listing_id));
      }
    }

    // return () => {
    //   dispatch(clearAllListings());
    // };
  }, [dispatch, listing_id]);

  const title = listing_id
    ? `${get_building_name(
        listing_id
      )} - Apartment Prices and Trends in Chicago | Rent-Arb`
    : "Rent-Arb: Apartments for Rent in Chicago";

  const description = listing_id
    ? `See the rent history, related apartments, and price trends for ${get_building_name(
        listing_id
      )} in Chicago, IL.`
    : "Sign an apartment lease with confidence using Rent-Arb. Discovery price trends and undervalued apartments.";

  const canon_url = listing_id
    ? `https://rent-arb.com/listing/${listing_id}`
    : "https://rent-arb.com";

  const is_mobile = window.innerWidth < 600;
  const LearnMore = () => {
    return (
      <div class="blog_container" style={{ margin: "0px 20px" }}>
        <Spacer />
        <ImageTextRow
          img={cat_tourist}
          title="New to Chicago?"
          text_provider={() => (
            <>
              Learn about how renting works in the windy city.{" "}
              <a class="underline_link" href="/new_renters" target="_blank">
                Learn More.{" "}
              </a>
            </>
          )}
          text_right={false}
        />
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canon_url}></link>

        <meta property="og:url" content={canon_url} />
        <meta property="og:title" content={title}></meta>
      </Helmet>
      <Navigation />
      {detailed_listing_state.web_unit && (
        <ShowListing listing_ts={detailed_listing_state} />
      )}
      <div className="explore-page">
        <Featured />
        <ListingsCarousel
          prevButtonClassName={generateRandomString(10)}
          nextButtonClassName={generateRandomString(10)}
          header={"Daily movers in Chicago"}
          paragraph={"Apartments making the biggest moves today"}
        />
      </div>
      <h1 className="tag-line">A smarter way to rent.</h1>

      {is_mobile && <LearnMore />}

      <Footer />
    </>
  );
};

export default SplashPage;
