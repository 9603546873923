import React from "react";

import "./Modal.scss";

export const Modal = (props) => {
  return (
    <aside
      id={props.id}
      className="modal-container"
      onClick={props.onClickOutside}
    >
      <div className="modal-area" style={props.modalAreaStyling}>
        {props.children}
      </div>
    </aside>
  );
};

export default Modal;
