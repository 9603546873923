import { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import DropDown from "../DropDown";
import CheckMarks from "./CheckMarks";

import "./CustomCheckboxes.scss";

const CustomCheckboxes = ({ OPTIONS, getter, setter, label = "Amenities" }) => {
  const dispatch = useDispatch();

  const previous_options = useSelector(getter());

  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (previous_options) {
      setSelectedOptions(previous_options);
    }
  }, [previous_options]);

  const deselectAll = useMemo(() => {
    return selectedOptions?.length === 0 ? "Deselect All" : "Select All";
  }, [selectedOptions]);

  const handleDeselectClick = useCallback(
    (e) => {
      e.preventDefault();

      let excludes;
      if (deselectAll === "Deselect All") {
        excludes = [...OPTIONS];
      }
      dispatch(setter(excludes || []));
    },
    [deselectAll]
  );

  const handleCheckMarkClick = useCallback(
    (e) => {
      const pre_click_options = previous_options ? previous_options : [];
      console.log(
        "clicked",
        e.target,
        e.target.type,
        e.target.checked,
        previous_options
      );
      if (e.target.type === "checkbox" && !e.target.checked) {
        dispatch(
          setter([
            ...pre_click_options.filter((element) => element !== e.target.name),
          ])
        );
        // dispatch(setter([...selectedOptions, e.target.name]));
      }

      if (e.target.checked) {
        dispatch(setter([...pre_click_options, e.target.name]));
        // dispatch(setter([...selectedOptions.filter(element => element !== e.target.name)]));
      }
    },
    [previous_options, setter, dispatch]
  );

  return (
    <DropDown buttonValue={label} selectedButton={true}>
      <div className="home-type-container" onClick={handleCheckMarkClick}>
        <div className="deselect-all-title-container">
          <h6>Required {label}</h6>

          <div className="deselect-btn-container" onClick={handleDeselectClick}>
            <button className="deselect-btn">
              <FontAwesomeIcon icon={faCheck} className="icon" />
            </button>
            <h6 className="deselect-text">{deselectAll}</h6>
          </div>
        </div>
        {OPTIONS.map((homeType, i) => {
          return (
            <CheckMarks
              key={i}
              homeType={homeType}
              defChecked={
                previous_options &&
                (previous_options.includes(homeType) ? true : false)
              }
            />
          );
        })}
      </div>
    </DropDown>
  );
};

export default CustomCheckboxes;
