import React, { useState, useEffect } from 'react';

const ImageWithTimeout = ({ src, alt }) => {
    const [loaded, setLoaded] = useState(false);
    const [timedOut, setTimedOut] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!loaded) setTimedOut(true);
        }, 5000); // Timeout after 5 seconds

        return () => clearTimeout(timer);
    }, [loaded]);

    return (
        <>
            {timedOut && !loaded ? (
                <div>Image failed to load.</div>
            ) : (
                <img
                    src={src}
                    onLoad={() => setLoaded(true)}
                    alt={alt}
                    style={{ display: loaded ? 'block' : 'none' }}
                />
            )}
        </>
    );
};

export default ImageWithTimeout;
