import React from "react";
import { Link } from "react-router-dom";

import "./UnitHeader.scss";

export function formatAvailabilityDate(input) {
  const months = {
    "jan.": "january",
    "feb.": "february",
    "mar.": "march",
    "apr.": "april",
    "may.": "may",
    "jun.": "june",
    "jul.": "july",
    "aug.": "august",
    "sep.": "september",
    "oct.": "october",
    "nov.": "november",
    "dec.": "december"
  };

  // Normalize input for processing
  let normalizedInput = input.toLowerCase().trim();
  normalizedInput = normalizedInput.split(',')[0];
  normalizedInput = normalizedInput.replace('availibility', 'available');

  // Replace abbreviated month names with full names
  Object.entries(months).forEach(([short, full]) => {
    normalizedInput = normalizedInput.replace(short, full);
  });

  // Check if 'available' is present at the start, if not, add it
  if (!normalizedInput.startsWith("available")) {
    normalizedInput = "available " + normalizedInput;
  }

  return normalizedInput;
}

const UnitHeader = ({ web_unit, use_spacer = true, show_details = true, is_available = true }) => {
  const unit = web_unit.unit;
  const price_change = web_unit.price_change;
  const formated_price = `${(unit.unit_price / 1000.0).toFixed(1)}k/mo`;

  const tick_symbol = price_change > 0 ? "↑" : "↓";
  const tick_color = price_change >= 0 ? "black" : "rgb(217, 0, 72)";
  const tick_price = Math.abs(price_change);
  const availablity_text = is_available ? formatAvailabilityDate(unit.unit_availibility) : "not available";
  const building_link = `/listing/${unit.building_name.replaceAll("-", "_").replaceAll(" ", "_")}`;
  return (
    <>
      <div className="price-plot-title swiper-no-swiping">
        <Link to={building_link} className="building-link">
          {unit.building_name}
        </Link> {' '}
        #{unit.unit_number}
      </div>
      {use_spacer && <div className="price-plot-spacer"></div>}
      <div className="price-plot-container swiper-no-swiping">
        <div className="price-plot-price-container">
          <div className="price-plot-price-container-row">
            <span className="price-plot-price-text">
              <span>
                <div className="price-plot-price-text-wrapper">
                  <span className="price-plot-price-title">
                    ${formated_price}
                  </span>
                  <span
                    className="price-plot-price-change"
                    style={{ color: tick_color }}
                  >
                    {" "}
                    {tick_symbol} ${tick_price}{" "}
                  </span>{" "}
                  this week
                </div>
                {show_details && (
                  <span className="price-plot-details">
                    {unit.beds} bed, {unit.unit_sqr_ft} sqft, {availablity_text}
                  </span>
                )}
              </span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnitHeader;
