import React from "react";
import { useDispatch } from "react-redux";
import { setAvailabilityFilter, getAvailabilityFilter } from "../../../../store/searchFilters";
import CustomDropdown from "../CustomDropdown/CustomDropdown";

import "./AvailabilityFilter.scss";

const AvailabilityDropdown = () => {
  const dispatch = useDispatch();

  const availabilityOptions = [
    { label: "Available now", value: 1 },
    { label: "Next week", value: 7 },
    { label: "This month", value: 30 },
    { label: "Next 4 months", value: 120 },
    { label: "Any time", value: 365 },
  ];

  const handleDropdownChange = (option) => {
    dispatch(setAvailabilityFilter(parseInt(option.value, 10)));
  };

  return (
    <div className="home-listing-type-wrapper" id="dropdown-wrapper">
      <CustomDropdown
        options={availabilityOptions}
        onSelect={handleDropdownChange}
        getOption={getAvailabilityFilter}
      />
    </div>
  );
};

export default AvailabilityDropdown;
