import { csrfFetch } from "./csrf";
import { objectToQuerySting } from "./utils";
import { cleanLocalStorageSearchCredentials } from "./utils";
import { createSelector } from "reselect";


const featuredSelector = state => state.featured


const RECEIVE_FEATURED = "api/featured/RECEIVE_FEATURED";
const CLEAR_FEATURED = "api/featured/CLEAR_FEATURED";

const receiveFeatured = (featured) => ({
  type: RECEIVE_FEATURED,
  featured,
});




export const getFeatured = createSelector([featuredSelector], featured => {
  if (featured) {
    return featured
  }

  return {};
});



export const fetchFeatured = () => async (dispatch) => {
  const res = await csrfFetch("/api/featured");

  if (res.ok) {
    const result = await res.json();
    dispatch(receiveFeatured(result));
  }
};

const featuredReducer = (state = {}, action) => {
  const newState = { ...state };

  switch (action.type) {
    case RECEIVE_FEATURED:
      return { ...newState, ...action.featured };
    case CLEAR_FEATURED:
      return {};
    default:
      return state;
  }
};

export default featuredReducer;
