import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addMonths, endOfMonth, startOfMonth } from 'date-fns';

// import { toggleModal, updateForm } from './modalSlice'; // Adjust the import path according to your file structure

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import InputField from './InputField';
import { getActiveUser } from "../../store/usersReducer";
import { bookTour } from '../../store/tourReducer';
import { createAccount } from '../../store/authReducer';

import './BookTourForm.scss';
import { getDetailedListing } from '../../store/detailedListingReducer';

function extractPhoneNumber(phoneNumberString) {
    const nonNumericCharacters = /\D/g;
    const numericPhoneNumber = phoneNumberString.replace(nonNumericCharacters, '');
    return numericPhoneNumber;
}

const BookTourForm = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector(getActiveUser());
    const listing_ts = useSelector(getDetailedListing);
    const [userInfo, setUserInfo] = useState({
        email: currentUser?.email || '',
        name: currentUser?.name || '',
        phone: currentUser?.phone || '',
        move_by_date: new Date(),
        availability: new Set(),
    });
    const [selectedDates, setSelectedDates] = useState([]);

    const minDate = new Date(); // Today
    const maxDate = endOfMonth(addMonths(minDate, 1));
    const eoyDate = endOfMonth(addMonths(minDate, 12));

    const handleAvailableDatesSelect = (date) => {
        const dateString = date.toISOString()
        const updatedDates = new Set(userInfo.availability);
        if (updatedDates.has(dateString)) {
            updatedDates.delete(dateString);
        } else {
            updatedDates.add(dateString);
        }
        setSelectedDates(Array.from(updatedDates, dateString => new Date(dateString)));
        console.log('selected_dates', selectedDates)
        setUserInfo((prevState) => ({
            ...prevState,
            availability: updatedDates,
        }));
    };

    const handleMoveByDateSelect = (date) => {
        setUserInfo((prevState) => ({
            ...prevState,
            move_by_date: date,
        }));
    }

    const handleInputChange = (e) => {
        let { name, value } = e.target;
        if (name === 'phone') {
            value = extractPhoneNumber(value);
        }
        setUserInfo((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(bookTour(listing_ts.web_unit.unit, userInfo));
    };

    return (
        <div className="user-profile-page-content booking-modal">
            <div style={{ margin: "0 0 8px 0" }}>
                <div className="title">
                    Book Tour
                </div>
            </div>
            <div className="grid-container booking-modal-grid">
                {/* <button onClick={handleClose}>Close</button> */}
                <form onSubmit={handleSubmit}>
                    <div className="other-fields-container">
                        <div className='row-container'>
                            <div className='left'>
                                <div width="1" className="field-wrapper">
                                    <div className="labeled-input">
                                        <label htmlFor="availability" className="mini-label">Your Availabile Dates</label>
                                        <DatePicker
                                            onSelect={handleAvailableDatesSelect}
                                            minDate={minDate}
                                            maxDate={maxDate}
                                            openToDate={minDate}
                                            highlightDates={selectedDates}
                                            selectsRange
                                            inline
                                            multiple
                                            isClearable
                                        />
                                    </div>
                                </div>
                                <button type="submit" className="submit-button" style={{ marginLeft: "10px" }}>Book Tour</button>
                            </div>
                            <div className='right'>
                                <InputField defaultValue={userInfo.name} onChange={handleInputChange} name="name" />
                                <InputField defaultValue={userInfo.phone} onChange={handleInputChange} name="phone" type="tel" />
                                <InputField defaultValue={userInfo.email} onChange={handleInputChange} name="email" />
                                <div width="1" className="field-wrapper">
                                    <div className="labeled-input">
                                        <label htmlFor="move_in" className="mini-label">Target Move-In Date</label>
                                        <DatePicker
                                            onSelect={handleMoveByDateSelect}
                                            minDate={minDate}
                                            className="text-input move-in-date-picker"
                                            type="date"
                                            selected={userInfo.move_by_date}
                                            dateFormat="yyyy-MM-dd"
                                            maxDate={eoyDate}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default BookTourForm;
