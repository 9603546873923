import { csrfFetch } from "./csrf";
import { fetchCurrentUser } from "./usersReducer";

const START_BOOKING = "START_BOOKING";
const REQUEST_TOUR = "REQUEST_TOUR";
const RECEIVE_TOURS = "RECEIVE_TOURS";
const REQUEST_SUCCESS = "REQUEST_SUCCESS";
const REQUEST_FAILED = "REQUEST_FAILED";
const END_BOOKING = "END_BOOKING";
const END_CELEBRATION = "END_CELEBRATION";

export const startBooking = () => ({
  type: START_BOOKING,
});

export const endBooking = () => ({
  type: END_BOOKING,
});

export const requestTour = () => ({
  type: REQUEST_TOUR,
});

export const receiveTours = (tours) => ({
  type: RECEIVE_TOURS,
  payload: tours,
});

export const requestSuccess = () => ({
  type: REQUEST_SUCCESS,
});

export const endCelebration = () => ({
  type: END_CELEBRATION,
});

export const requestFailed = (error) => ({
  type: REQUEST_FAILED,
  payload: error,
});

export const bookTour = (unit, tour_form) => async (dispatch) => {
  try {
    console.log("book tour", unit, tour_form);
    const tour = {
      building_name: unit.building_name,
      unit_numbers: String(unit.unit_number),
      unit_ids: unit.id,
      tour_availability_dates: Array.from(tour_form.availability)
        .map((date_str) => date_str.slice(0, 10))
        .join(" "),
      email: tour_form.email,
      name: tour_form.name,
      phone: Number(tour_form.phone),
      move_by_date: tour_form.move_by_date.toISOString().slice(0, 10),
    };
    console.log("tour", tour);
    let res = await csrfFetch("/users/tours/new/", {
      method: "POST",
      body: JSON.stringify(tour),
    });
    if (res.ok) {
      const tour_response = await res.json();
      console.log("booked tour", tour_response);
      dispatch(requestSuccess());
      dispatch(fetchCurrentUser());
      setTimeout(() => dispatch(endCelebration()), 5000);
    } else {
      dispatch(requestFailed("Failed to book tour"));
    }
  } catch (error) {
    throw error;
  }
};

const initialState = {
  isLoading: false,
  tours: [],
  error: null,
  isBooking: false,
  celebrate: false,
};

const toursReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_BOOKING:
      return {
        ...state,
        isBooking: true,
      };
    case REQUEST_TOUR:
      return {
        ...state,
        isLoading: true,
        error: null, // Reset any errors from previous requests
      };
    case RECEIVE_TOURS:
      return {
        ...state,
        isLoading: false,
        tours: action.payload, // Update the state with the received tours
        error: null,
      };
    case REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null, // Reset any errors from previous requests
        isBooking: false,
        celebrate: true,
      };
    case REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload, // Capture and store the error in the state
      };
    case END_BOOKING:
      return {
        ...state,
        isBooking: false,
      };
    case END_CELEBRATION:
      return {
        ...state,
        celebrate: false,
      };
    default:
      return state;
  }
};

export default toursReducer;
