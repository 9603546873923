import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import "./index.scss";

const Footer = () => {
	return (
		<>
			<footer className="root-0-1-693">
				<div className="content-0-1-694">
					<div className="gridContainer-0-1-696">
						<div className="gridSection-0-1-697">
							<div style={{ display: "flex", flexDirection: "column" }}>
								<div
									style={{
										fontFamily: "var(--inter-font)",
										paddingBottom: "8px",
									}}
								>
									<span className="footer-title">Company</span>
								</div>
								{/* <a
									target="_blank"
									rel="noopener noreferrer"
									href="/press"
									className="footerItemText-0-1-695"
								>
									<span className="footer-text">Press</span>
								</a> */}
								{/* <a
									target="_blank"
									rel="noopener noreferrer"
									href="/about"
									className="footerItemText-0-1-695"
								>
									<span className="footer-text">About</span>
								</a> */}
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="/contact"
									className="footerItemText-0-1-695"
								>
									<span className="footer-text">Contact</span>
								</a>
								{/* <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/privacy-policy"
                  className="footerItemText-0-1-695"
                >
                  <span className="footer-text">Privacy Policy</span>
                </a> */}
							</div>
						</div>
						<div className="gridSection-0-1-697">
							<div style={{ display: "flex", flexDirection: "column" }}>
								<div
									style={{
										fontFamily: "var(--inter-font)",
										paddingBottom: "8px",
									}}
								>
									<span className="footer-title">Social</span>
								</div>
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="https://twitter.com/"
									className="footerItemText-0-1-695"
								>
									<span className="footer-text">Twitter</span>
								</a>
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="https://linkedin.com/"
									className="footerItemText-0-1-695"
								>
									<span className="footer-text">LinkedIn</span>
								</a>
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="https://instagram.com/"
									className="footerItem

		Text-0-1-695"
								>
									<span className="footer-text">Instagram</span>
								</a>
							</div>
						</div>
						<div className="gridSection-0-1-697">
							<div style={{ display: "flex", flexDirection: "column" }}>
								<div
									style={{
										fontFamily: "var(--inter-font)",
										paddingBottom: "8px",
									}}
								>
									<span className="footer-title">Product</span>
								</div>
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="/new_renters"
									className="footerItemText-0-1-695"
								>
									<span className="footer-text">Renting for beginners</span>
								</a>
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="/contact"
									className="footerItemText-0-1-695"
								>
									<span className="footer-text">Feedback</span>
								</a>
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="/contact"
									className="footerItemText-0-1-695"
								>
									<span className="footer-text">Help</span>
								</a>
							</div>
						</div>
					</div>
					<hr
						className="root-0-1-698"
						style={{
							backgroundColor: "rgba(0, 0, 0, 0.05)",
							width: "calc(100% + 0px)",
							height: "1px",
							marginInline: "0px",
							marginBlock: "16px",
						}}
					/>
					<span
						data-nosnippet="true"
						style={{
							color: "rgba(0, 0, 0, 0.6)",
							fontVariationSettings: '"slnt" 0',
							fontSize: "14px",
							lineHeight: "20px",
							fontWeight: 800,
						}}
					>
						© 2024 Anchovy Group.
					</span>
					{/* <div data-nosnippet="true" style={{ paddingTop: "8px" }}>
    <span style={{
        color: "rgba(0, 0, 0, 0.6)",
        fontVariationSettings: '"slnt" 0',
        fontSize: "12px",
        lineHeight: "18px",
        fontWeight: 400,
    }}>
        Information provided is based on data and resources believed to be reliable. However, the accuracy, completeness, and reliability of this information cannot be guaranteed by our company. We do not warrant or represent that the information provided is current, uninterrupted, or error-free. We disclaim all liability for any errors or other inaccuracies relating to the information and description of the properties listed.
        <br /><br />
        All property listings are subject to change and are contingent upon availability. The properties listed on our website are for informational purposes only and under no circumstances should any information on this site be used as a basis for making financial decisions. Prices and terms of occupancy are subject to change without prior notice.
        <br /><br />
        Leasing real estate involves risk and may not be suitable for all individuals. Before deciding to lease a property through our website, you should carefully consider your financial situation and consult with a financial advisor to ensure it is appropriate for your circumstances. Any leasing or other financial decisions made based on information from this site are at your own risk and our company assumes no responsibility for outcomes.
        <br /><br />
        Past performance of real estate markets is not indicative of future results. Any historical data provided on our site are for reference purposes only.
    </span>
</div> */}
				</div>
			</footer>
		</>
	);
};

export default Footer;
