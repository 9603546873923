import React from "react";
// import "./PriceGraph.scss";
import Plot from 'react-plotly.js';

const MAIN_TRACE_DEFAULTS = {
  type: 'scatter',
  mode: 'lines',
  marker: { color: 'black' },
  hoverinfo: 'y',
  showlegend: false,
  connectgaps: true,
  line: {
    shape: 'spline'
  },
};

const BUILDING_TRACE_DEFAULTS = {
  type: 'scatter',
  mode: 'lines+markers',
  marker: { color: 'black', size: 7 },
  hoverinfo: 'x+y+text',
  showlegend: false,
  connectgaps: true,
  line: {
    color: 'black'
  }
};

const LAST_POINT_TRACE_DEFAULTS = {
  type: 'scatter',
  mode: 'markers',
  marker: { color: 'black', size: 7 },
  hoverinfo: 'y',
  showlegend: false,
};

const LAYOUT_DEFAULTS = {
  width: 550,
  height: 240,
  plot_bgcolor: 'white',
  paper_bgcolor: 'white',
  margin: {
    l: 70, // Left margin
    r: 60, // Right margin
    b: 20, // Bottom margin
    t: 0, // Top margin
    pad: 4 // Padding between the plotting area and the axis lines
  },
  dragmode: false,
  yaxis: {
    tickprefix: '$',
  },
};

const CONFIG_DEFAULTS = {
  staticPlot: false,
  displaylogo: false,
  scrollZoom: false, // disables zooming with scroll
  dragMode: false, // disables panning or moving the graph
  displayModeBar: false // optionally hides the mode bar
};


function PlotlyComponent({ x, y, layout_overrides }) {
  const main_trace = {
    ...MAIN_TRACE_DEFAULTS,
    x: x,
    y: y,
  };

  const last_point_trace = {
    ...LAST_POINT_TRACE_DEFAULTS,
    x: [x[x.length - 1]],
    y: [y[y.length - 1]],
  };

  const layout = { ...LAYOUT_DEFAULTS, ...layout_overrides }

  return (
    <Plot
      data={[
        main_trace, last_point_trace
      ]}
      layout={layout}
      config={CONFIG_DEFAULTS}
    />
  );
}

export const EXAMPLE_DATASET = [
  {
    x: ['2021-01-01', '2021-01-02', '2021-01-03'], // Dates
    y: [10, 15, 14], // Prices
    color: 'red',
    name: 'Product A'
  },
  {
    x: ['2021-01-01', '2021-01-02', '2021-01-03'], // Dates
    y: [20, 19, 21], // Prices
    color: 'blue',
    name: 'Product B'
  }
];

export const MUTE_COLORS = [
  '#463F3A',
  '#8A817C',
  '#BCB8B1',
]

export const BRIGHT_COLORS = [
  '#F5CAC3',
  '#F6BD60',
  '#76A99C',
  '#F28482',
]

export const COLOR_SCHEME = [
  ...BRIGHT_COLORS,
  ...MUTE_COLORS,
];

const MULTI_TRACE_LAYOUT_DEFAULTS = {
  ...MAIN_TRACE_DEFAULTS,
  showlegend: true,
  hoverinfo: 'y+name',
};

const MULTI_LAYOUT_DEFAULTS = {
  ...LAYOUT_DEFAULTS,
  width: 700,
  height: 400,
};

export const MultiPriceGraph = ({ datasets, layout_overrides, config_overrides = {}, onClick = () => { } }) => {
  // Create a trace for each dataset
  const traces = datasets.map(trace => ({
    ...MULTI_TRACE_LAYOUT_DEFAULTS,
    x: trace.x,
    y: trace.y,
    marker: {
      color: trace.color,  // Each dataset can specify its own color
    },
    name: trace.name,  // Optional: Include a name for each series for the legend
  }));

  const last_point_traces = datasets.map(dataset => ({
    ...LAST_POINT_TRACE_DEFAULTS,
    x: [dataset.x[dataset.x.length - 1]],
    y: [dataset.y[dataset.y.length - 1]],
    marker: {
      color: dataset.color,  // Each dataset can specify its own color
    },
  }));

  const layout_defaults = {
    ...MULTI_LAYOUT_DEFAULTS,
    showlegend: datasets.some(dataset => dataset.name != null),
  };

  const layout = { ...layout_defaults, ...layout_overrides };
  const config = { ...CONFIG_DEFAULTS, ...config_overrides };

  return (
    <Plot
      data={[...traces, ...last_point_traces]}
      layout={layout}
      config={config}
      onClick={onClick}
      onHover={(event) => event.event.target.style.cursor = 'pointer'}
    />
  );
};

export const getDataset = (listings) => {

  const is_available = (unit_ts) => unit_ts.rent_ts[unit_ts.rent_ts.length - 1];

  const get_color = (unit_ts, i) => {
    if (is_available(unit_ts)) {
      return BRIGHT_COLORS[i % BRIGHT_COLORS.length];
    }
    return MUTE_COLORS[i % MUTE_COLORS.length];
  }

  const not_available = listings.filter(unit_ts => !is_available(unit_ts));
  const available = listings.filter(is_available);

  return [...not_available, ...available].map((unit_ts, i) => {
    const unit = unit_ts.web_unit.unit;
    return {
      x: unit_ts.dates,
      y: unit_ts.rent_ts,
      color: get_color(unit_ts, i),
      name: `${unit.building_name} #${unit.unit_number}`
    };
  });
};

export const getDatasetSelected = (listings, selected_id, same_building = true) => {

  const get_color = (unit_ts) => {
    if (unit_ts.web_unit.unit.id === selected_id) {
      return 'rgba(217, 0, 72, 1)';
    }
    return 'black';
  }

  const get_name = (unit_ts) => {
    const unit = unit_ts.web_unit.unit;
    if (same_building) {
      return `unit #${unit.unit_number}`;
    }
    return `${unit.building_name} #${unit.unit_number}`;
  }

  return listings.map((unit_ts) => {
    return {
      x: unit_ts.dates,
      y: unit_ts.rent_ts,
      color: get_color(unit_ts),
      name: get_name(unit_ts),
      order: unit_ts.web_unit.unit.id === selected_id ? 1 : 0,
    };
  }).sort((a, b) => a.order - b.order);
};

export const getDatasetPriceMove = (listings, same_building = true) => {

  const get_color = (unit_ts) => {
    if (unit_ts.web_unit.price_change < 0) {
      return 'rgba(217, 0, 72, 1)';
    }
    return 'black';
  }

  const get_name = (unit_ts) => {
    const unit = unit_ts.web_unit.unit;
    if (same_building) {
      return `unit #${unit.unit_number}`;
    }
    return `${unit.building_name} #${unit.unit_number}`;
  }

  return listings.map((unit_ts) => {
    return {
      x: unit_ts.dates,
      y: unit_ts.rent_ts,
      color: get_color(unit_ts),
      name: get_name(unit_ts),
    };
  });
};

export function BuildingPriceGraphComponent({ x, y, text, highlight_x, trace_overrides, layout_overrides, onPointClick }) {
  const main_trace = {
    ...BUILDING_TRACE_DEFAULTS,
    ...trace_overrides,
    x: x,
    y: y,
    text: text,
  };

  const highlight_y = y[x.indexOf(highlight_x)];
  const highlight_text = text[x.indexOf(highlight_x)];

  const highlight_point_trace = {
    ...LAST_POINT_TRACE_DEFAULTS,
    marker: { color: 'rgb(217, 0, 72)', size: 10 },
    hoverinfo: 'x+y+text',
    x: [highlight_x],
    y: [highlight_y],
    text: [highlight_text],
  };

  const layout = { ...LAYOUT_DEFAULTS, hovermode: 'closest', ...layout_overrides }
  const config = { ...CONFIG_DEFAULTS, responsive: true, }

  return (
    <Plot
      data={[
        main_trace, highlight_point_trace
      ]}
      layout={layout}
      config={config}
      onClick={onPointClick}
      onHover={(event) => event.event.target.style.cursor = 'pointer'}
    />
  );
}

export default PlotlyComponent;
