import { csrfFetch } from "./csrf";
import { objectToQuerySting } from "./utils";
import { cleanLocalStorageSearchCredentials } from "./utils";
import { createSelector } from "reselect";

const detailedListingSelector = (state) => state.detailedListing;

const PICK_DETAILED_LISTING = "api/detailedListing/PICK_DETAILED_LISTING";
const RECEIVE_DETAILED_LISTING = "api/detailedListing/RECEIVE_DETAILED_LISTING";
const CLEAR_DETAILED_LISTING = "api/detailedListing/CLEAR_DETAILED_LISTING";

export const receiveDetailedListing = (detailedListing) => ({
  type: RECEIVE_DETAILED_LISTING,
  detailedListing,
});

export const pickDetailedListing = (detailedListing) => ({
  type: PICK_DETAILED_LISTING,
  detailedListing,
});

export const clearDetailedListing = () => ({
  type: CLEAR_DETAILED_LISTING,
});

export const getDetailedListing = createSelector(
  [detailedListingSelector],
  (detailedListing) => {
    if (detailedListing) {
      return detailedListing;
    }

    return {};
  }
);

export const fetchDetailedListing = (listing_id) => async (dispatch) => {
  const res = await csrfFetch(`/api/listing/${listing_id}`);

  if (res.ok) {
    const result = await res.json();
    dispatch(receiveDetailedListing(result));
  }
};

export const fetchBuilding = (building_name) => async (dispatch) => {
  const res = await csrfFetch(`/api/building/${building_name}`);

  if (res.ok) {
    const result = await res.json();
    dispatch(receiveDetailedListing(result));
  }
};

const detailedListingReducer = (state = {}, action) => {
  const newState = { ...state };

  switch (action.type) {
    case PICK_DETAILED_LISTING:
      return { ...newState, ...action.detailedListing };
    case RECEIVE_DETAILED_LISTING:
      let new_path = `/listing/${action.detailedListing.web_unit.unit.id}`;
      if (action.detailedListing.web_unit.unit.unit_sqr_ft === 0) {
        new_path = `/listing/${action.detailedListing.web_unit.unit.building_name
          .replaceAll(" ", "_")
          .replaceAll("-", "_")}`;
      }
      console.log(
        "new_path",
        action.detailedListing.web_unit.unit.unit_sqr_ft === 0,
        new_path
      );
      window.history.pushState({}, "", new_path);
      return { ...newState, ...action.detailedListing };
    case CLEAR_DETAILED_LISTING:
      window.history.pushState({}, "", `/`);
      return {};
    default:
      return state;
  }
};

export default detailedListingReducer;
