import { useRef, useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, useMap, Tooltip } from "react-leaflet";

import "leaflet/dist/leaflet.css";
import "./MapComponent.scss";
import L from "leaflet";

const houseIcon = L.divIcon({
  html: '<div style="font-size: 18px;">🏚️</div>', // Define the emoji and style as needed
  className: "custom-div-icon", // Custom class for additional styling
  iconSize: [50, 50], // Size of the icon
  iconAnchor: [25, 25], // Adjust based on the actual size to anchor the icon properly
});

const castleIcon = L.divIcon({
  html: '<div style="font-size: 26px;">🏰</div>', // Define the emoji and style as needed
  className: "custom-div-icon", // Custom class for additional styling
  iconSize: [50, 50], // Size of the icon
  iconAnchor: [25, 25], // Adjust based on the actual size to anchor the icon properly
});


function RecenterMap({ center }) {
  const map = useMap(); // Access the map instance

  useEffect(() => {
    map.flyTo(center, map.getZoom()); // Recenter the map to the new coordinates
  }, [center, map]); // Dependency array includes `center` to re-run when it changes

  return null; // This component does not render anything itself
}

const DEFAULT_LOCATION = [41.8781, -87.6298];

const MapComponent = ({ buildings, highlighted_building, on_click = () => { } }) => {
  const [mapCenter, setMapCenter] = useState(DEFAULT_LOCATION); // Default to Chicago's general coordinates
  useEffect(() => {
    setMapCenter(buildings[highlighted_building] || DEFAULT_LOCATION)
  }, [buildings, highlighted_building]);

  return (
    <MapContainer
      center={mapCenter}
      zoom={15}
      style={{ height: "100%", width: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {
        Object.entries(buildings).map(([building_name, { lat, lon }]) => (
          // <Marker key={building_name} position={[lat, lon]} icon={houseIcon}></Marker>
          <Marker
            key={building_name} position={[lat, lon]} icon={highlighted_building === building_name ? castleIcon : houseIcon}
            eventHandlers={{
              click: () => on_click(building_name),
            }}
          >
            <Tooltip>{building_name}</Tooltip>
          </Marker>
        ))
      }
      <RecenterMap center={mapCenter} />
    </MapContainer>
  );
};

export default MapComponent;
