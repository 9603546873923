import BedsAndBaths from "./BedsAndBaths/BedsAndBaths";
import PriceRange from "./PriceRange/PriceRange";
import HomeType from "./HomeType/HomeType";
import SquareFootDropdown from "./SquareFootFilter/SquareFootFilter";
import AreaFilter from "./AreaFilter/AreaFilter";
import AvailabilityDropdown from "./AvailabilityFilter/AvailabilityFilter";

import "./HomeListingType/HomeListingType.scss";

const FilterButtons = () => {
  return (
    <>
      <PriceRange />
      <SquareFootDropdown />
      <BedsAndBaths />
      <HomeType />
      <AreaFilter />
      <AvailabilityDropdown />
    </>
  );
};

export default FilterButtons;
