import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFavoriteListings, getFavorites } from "../../store/listingsReducer";

import ListingItem from "../ListingItem/ListingItem";
import { MultiPriceGraph, getDataset } from "../PriceGraph/PriceGraph";

const Favorites = ({ fav_units }) => {
	const dispatch = useDispatch();

	let listings = useSelector(getFavorites);
	listings = listings.filter((listing) => fav_units.includes(listing.web_unit.unit.id));
	console.log("listings", listings);

	useEffect(() => {
		if (fav_units?.length > 0) {
			dispatch(fetchFavoriteListings(fav_units));
		}
	}, [dispatch, fav_units]);

	const listingStyling = {
		minWidth: "286px",
		height: "290px",
	};

	const openNewWindow = (listing_ts) => () => {
		const unit_id = listing_ts.web_unit.unit.id;
		const listing_website = `/listing/${unit_id}`;
		window.open(listing_website, "_blank", "noopener,noreferrer");
	};

	const isMobile = window.innerWidth < 600;
	const datasets = getDataset(listings);
	let layout_overrides = {};
	if (isMobile) {
		layout_overrides = {
			width: window.innerWidth,
			height: 300,
			showlegend: false,
		};
	}

	return (
		listings && (
			<div className="favourites_wrapper">
				<div className="header-wrapper">
					<h1 className="favourites-title">Your Homes</h1>
				</div>

				<div className="multi-price-graph-wrapper">
					<MultiPriceGraph datasets={datasets} layout_overrides={layout_overrides} />
				</div>

				<div className="listing-cards-wrapper">
					{JSON.stringify(listings) === "[]" && <h1>No favorites yet</h1>}
					{listings.map((listing, i) => (
						<ListingItem
							listing_ts={listing}
							listingStyling={listingStyling}
							index={listing.web_unit.unit.unit_number}
							onClick={openNewWindow(listing)}
							show_favorite={true}
						/>
					))}
				</div>
			</div>
		)
	);
};

export default Favorites;
